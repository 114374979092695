import PropTypes from "prop-types";

import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";

import VisibilityIcon from "@mui/icons-material/Visibility";

import MDBox from "components/atoms/MDBox";

function CheckboxGroupWrapper({
  name,
  value,
  state,
  onChange,
  label,
  options,
  disabled,
  error,
  showTitle,
  nameProperty,
  valueProperty,
  linkProperty,
  hiddenOptions,
  ...rest
}) {
  return (
    <FormControl component="fieldset" error={error}>
      {showTitle && <FormLabel component="legend">{label}</FormLabel>}
      <FormGroup name={name} {...rest}>
        {options.map((element, index) => {
          let checkboxName = null;
          let checkboxValue = null;
          let checkboxLink = null;
          if (element) {
            if (typeof element === "string") {
              checkboxName = element;
              checkboxValue = element;
              checkboxLink = null;
            } else {
              checkboxName = element[nameProperty];
              checkboxValue = element[valueProperty];
              checkboxLink = element[linkProperty];
            }
          }

          return (
            <MDBox key={checkboxName} display="flex" justifyContent="left">
              <FormControlLabel
                disabled={disabled}
                control={
                  <Checkbox
                    value={checkboxValue}
                    checked={state[checkboxValue] ?? false}
                    onChange={onChange}
                    name={checkboxName}
                  />
                }
                label={checkboxName}
                sx={
                  hiddenOptions?.includes(checkboxValue)
                    ? { display: "none" }
                    : {}
                }
              ></FormControlLabel>
              {checkboxLink && (
                <IconButton
                  edge="start"
                  size="small"
                  color="info"
                  href={checkboxLink}
                  target="_blank"
                  rel="noopener"
                >
                  <VisibilityIcon />
                </IconButton>
              )}
            </MDBox>
          );
        })}
        {!options.length && "-"}
      </FormGroup>
    </FormControl>
  );
}

// Setting default values for the props of RadioGroupWrapper
CheckboxGroupWrapper.defaultProps = {
  showTitle: true,
};

// typechecking props for CheckboxGroupWrapper
CheckboxGroupWrapper.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  showTitle: PropTypes.bool,
};

export default CheckboxGroupWrapper;
