import { createContext, useContext, useReducer, useEffect } from "react";
import PropTypes from "prop-types";

global.appVersion = process.env.REACT_APP_VERSION;

const CacheContext = createContext();
CacheContext.displayName = "CacheContext";

const cacheReducer = (state, action) => {
  const loadingState = action?.payload?.loading;
  const currentVersionState = action?.payload?.currentVersion;
  const latestVersionState = action?.payload?.latestVersion;
  const isLatestVersionState = action?.payload?.isLatestVersion;
  const refreshCacheAndReloadState = () => {
    console.log("Clearing cache and hard reloading...");
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then(function (names) {
        for (let name of names) caches.delete(name);
      });
    }
    // delete browser cache and hard reload
    window.location.reload(true);
  };

  switch (action.type) {
    case "CACHE_IS_READY":
      return {
        ...state,
        loading: loadingState,
        currentVersion: currentVersionState,
        latestVersion: latestVersionState,
        isLatestVersion: isLatestVersionState,
        refreshCacheAndReload: refreshCacheAndReloadState,
      };
    default:
      return state;
  }
};

const semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA.split(/\./g);

  const versionsB = versionB.split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};

const CacheContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(cacheReducer, {
    loading: false,
    currentVersion: "",
    latestVersion: "",
    isLatestVersion: true,
    refreshCacheAndReload: null,
  });

  useEffect(() => {
    const checkVersion = setInterval(
      (function check() {
        fetch("/meta.json")
          .then((response) => response.json())
          .then((meta) => {
            const latestVersion = meta.version;
            const currentVersion = global.appVersion;
            const shouldForceRefresh = semverGreaterThan(
              latestVersion,
              currentVersion
            );
            if (shouldForceRefresh) {
              console.log(
                `We have a new version - ${latestVersion}. Should force refresh`
              );
              dispatch({
                type: "CACHE_IS_READY",
                payload: {
                  loading: false,
                  currentVersion: currentVersion,
                  latestVersion: latestVersion,
                  isLatestVersion: false,
                },
              });
            } else if (state.currentVersion === "") {
              console.log(
                `You already have the latest version - ${latestVersion}. No cache refresh needed.`
              );
              dispatch({
                type: "CACHE_IS_READY",
                payload: {
                  loading: false,
                  currentVersion: currentVersion,
                  latestVersion: latestVersion,
                  isLatestVersion: true,
                },
              });
            }
          })
          .catch((error) => {
            console.error(error);
          });
        return check;
      })(),
      60000
    );

    return () => {
      clearInterval(checkVersion);
    };
  }, [state.currentVersion]);

  return (
    <CacheContext.Provider value={{ ...state, dispatch }}>
      {children}
    </CacheContext.Provider>
  );
};

const useCacheContext = () => {
  const context = useContext(CacheContext);

  if (!context) {
    throw Error("useCacheContext must be used inside an CacheContextProvider");
  }

  return context;
};

CacheContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { CacheContextProvider, useCacheContext };
