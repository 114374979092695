import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";

import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";
import MDAvatar from "components/atoms/MDAvatar";

function Profile({ mainForm, mainFormField, modeDisabled, dispatchMainError }) {
  const { values } = mainForm;

  return (
    <Card id="profile">
      <MDBox p={2}>
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <MDAvatar bgColor="info" size="xl">
              {values?.displayName?.charAt(0).toUpperCase()}
            </MDAvatar>
          </Grid>
          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                {values?.displayName ?? "-"}
              </MDTypography>
              <MDTypography variant="button" color="text" fontWeight="medium">
                {values?.designation ?? "-"}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3} sx={{ ml: "auto" }}>
            <MDBox
              display="flex"
              justifyContent={{ md: "flex-end" }}
              alignItems="center"
              lineHeight={1}
            >
              <MDBox>
                <Stack direction="row" spacing={1}>
                  {values?.roles.map((role) => {
                    return (
                      <Chip
                        key={role}
                        label={role}
                        size="small"
                        color="primary"
                      />
                    );
                  })}
                </Stack>
              </MDBox>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default Profile;
