import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";

import FormCheckboxGroup from "components/molecules/Formik/FormCheckboxGroup";

import { permissionOptions } from "pages/settings/roles/manage/schemas/setups";

function Permissions({
  mainForm,
  mainFormField,
  modeDisabled,
  dispatchMainError,
}) {
  const { permissions } = mainFormField;

  return (
    <Card id="permissions" sx={{ overflow: "visible" }}>
      <MDBox m={3}>
        <MDBox mb={3}>
          <MDTypography variant="h5">Permissions</MDTypography>
        </MDBox>
        <MDBox mb={1}>
          <MDTypography variant="body3" fontWeight="bold" color="error">
            Warning !!! Do not amend roles and permissions during business
            hours, any changes will cause a refresh of the web application due
            to security enforcement, users may lost their work in progress.{" "}
          </MDTypography>
        </MDBox>
        <MDBox>
          <Grid
            container
            rowSpacing={{ xs: 1, sm: 2 }}
            columnSpacing={{ xs: 1, sm: 3 }}
          >
            <Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                {permissions.label}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={10}>
              <FormCheckboxGroup
                showTitle={false}
                form={mainForm}
                field={permissions}
                options={permissionOptions}
                disabled={modeDisabled}
                row
              />
            </Grid>
            <Grid item xs={12} sm={4}></Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default Permissions;
