import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";

import { ErrorMessage, Field } from "formik";
import CheckboxGroupWrapper from "components/molecules/Formik/Wrapper/CheckboxGroupWrapper";

function FormCheckboxGroup({
  form,
  field,
  options,
  onChange,
  disabled,
  nameProperty,
  valueProperty,
  linkProperty,
  ...rest
}) {
  const { values, setFieldValue } = form;
  const value = values[field.name] ? values[field.name] : null;

  // object of {checkbox1name: boolean, checkbox2name: boolean ... } to keep track of each checkbox state
  const [isCheckedState, setIsCheckedState] = useState({});

  useEffect(() => {
    // converting array into object
    const initCheckedState = options.reduce((prev, curr) => {
      const stateObj = {};
      if (curr) {
        typeof curr === "string"
          ? (stateObj[curr] = false)
          : (stateObj[curr[valueProperty]] = false);
      }
      return { ...prev, ...stateObj };
    }, {});

    values[field.name].forEach((element) => {
      if (element) {
        typeof element === "string"
          ? (initCheckedState[element] = true)
          : (initCheckedState[element[valueProperty]] = true);
      }
    });

    setIsCheckedState(initCheckedState);
  }, [field.name, valueProperty, options, values]);

  const handleChange =
    onChange ??
    ((e) => {
      isCheckedState[e.target.value] = e.target.checked;

      const selectedOptions = Object.entries(isCheckedState)
        .filter(([k, v]) => v === true)
        .map(([k, v]) => {
          return options.find((option) => {
            if (option) {
              if (typeof option === "string") {
                return option === k;
              } else {
                return option[valueProperty] === k;
              }
            } else {
              return false;
            }
          });
        });

      setFieldValue(field.name, selectedOptions);
    });

  return (
    <MDBox mb={2}>
      <Field
        name={field.name}
        label={field.label}
        value={value}
        state={isCheckedState}
        options={options}
        onChange={handleChange}
        as={CheckboxGroupWrapper}
        disabled={disabled}
        nameProperty={nameProperty}
        valueProperty={valueProperty}
        linkProperty={linkProperty}
        {...rest}
      />
      <MDBox mt={0.75}>
        <MDTypography
          component="div"
          variant="caption"
          color="error"
          fontWeight="regular"
          sx={{ whiteSpace: "pre-wrap" }}
        >
          <ErrorMessage name={field.name}></ErrorMessage>
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

// typechecking props for FormCheckboxGroup
FormCheckboxGroup.propTypes = {
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  options: PropTypes.array,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  showTitle: PropTypes.bool,
  children: PropTypes.node,
};

export default FormCheckboxGroup;
