import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";
import MDButton from "components/atoms/MDButton";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";

import FormTextFieldFast from "components/molecules/Formik/FormTextFieldFast";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { DatePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { sendWhatsAppMsg } from "services/callable";
import { useCollectionSnapshot } from "hooks/useCollectionSnapshot";

function EditParticipant({
  mainForm,
  mainFormField,
  modeDisabled,
  setShowEditPanel,
}) {
  const { mobile, whatsappInvite, participantGroup, dob } = mainFormField;
  const { setFieldValue } = mainForm;
  const [inviteCount, setInviteCount] = useState(0);
  const [selectedDOB, setSelectedDOB] = useState(null);

  const nudgesQueries = {
    whereQueries: [
      {
        field: "deletedAt",
        condition: "==",
        value: null,
      },
    ],
  };
  const { collectionData: nudgesData } = useCollectionSnapshot(
    "nudges",
    nudgesQueries
  );
  const groupOptions = nudgesData.map((nudgeData) => {
    return nudgeData.data.displayName;
  });

  const [open, setOpen] = useState(false);
  const showDialog = () => {
    setOpen(true);
  };
  const closeDialog = () => {
    setOpen(false);
  };

  const [openFailToSendDialog, setOpenFailToSendDialog] = useState(false);
  const showFailToSendDialog = () => {
    setOpenFailToSendDialog(true);
  };
  const closeFailToSendDialog = () => {
    setOpenFailToSendDialog(false);
  };

  useEffect(() => {
    try {
      if (mainForm.values.dob) {
        setSelectedDOB(mainForm.values.dob);
      } else setSelectedDOB(null);
    } catch (err) {
      console.log(err);
    }
  }, [mainForm]);

  return (
    <MDBox m={3}>
      <MDBox mb={3}>
        <MDBox width="100%" display="flex" justifyContent="space-between">
          <MDTypography variant="h5">Edit Information</MDTypography>
          <MDBox></MDBox>
          <MDButton
            size="small"
            variant="outlined"
            color="info"
            iconOnly
            onClick={async () => {
              setShowEditPanel(false);
            }}
          >
            <CloseIcon />
          </MDButton>
        </MDBox>
      </MDBox>

      <MDBox>
        <Grid
          container
          rowSpacing={{ xs: 1, sm: 2 }}
          columnSpacing={{ xs: 1, sm: 3 }}
        >
          <Grid item xs={12} sm={2}>
            <MDTypography variant="body3" fontWeight="bold">
              {mobile.label}
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <PhoneInput
              country={"sg"}
              value={mainForm.values.mobile}
              onChange={(value) => {
                if (value.length > 0) {
                  mainForm.values.mobile = value;
                  setFieldValue(mobile.name, value);
                }
              }}
            />
            <FormTextFieldFast
              form={mainForm}
              field={mobile}
              variant="standard"
              disabled={true}
              hideLabel={true}
              hideField={true}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <MDButton
              variant="gradient"
              color="success"
              onClick={async () => {
                if (mainForm.values.mobile.length === 10) {
                  const response = await sendWhatsAppMsg({
                    type: "template",
                    name: "cradle2_welcome",
                    mobile: mainForm.values.mobile,
                  });
                  console.log(JSON.stringify(response));
                  if (response.data.statusCode < 400) {
                    const newInviteCount = inviteCount + 1;
                    mainForm.values.whatsappInvite = newInviteCount;
                    setInviteCount(newInviteCount);
                    setFieldValue(
                      whatsappInvite.name,
                      newInviteCount.toString()
                    );
                  } else showFailToSendDialog();
                } else {
                  showDialog();
                }
              }}
            >
              WhatsApp Invite
            </MDButton>
          </Grid>

          <Grid item xs={12} sm={2}></Grid>
          <Grid item xs={12} sm={6}>
            {inviteCount > 0 && (
              <MDTypography variant="body3" fontWeight="bold" color="success">
                WhatsApp Invite Sent - #{inviteCount}
              </MDTypography>
            )}
            <FormTextFieldFast
              form={mainForm}
              field={whatsappInvite}
              variant="standard"
              disabled={true}
              hideLabel={true}
              hideField={true}
            />
          </Grid>
          <Grid item xs={12} sm={4}></Grid>

          <Grid item xs={12} sm={2}>
            <MDTypography variant="body3" fontWeight="bold">
              {participantGroup.label}
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              name={`child1.participantGroup`}
              size="small"
              value={mainForm.values.participantGroup}
              getOptionLabel={(option) => option ?? ""}
              options={groupOptions}
              disabled={true}
              renderInput={(params) => (
                <TextField {...params} label="Select Participant Group" />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}></Grid>

          <Grid item xs={12} sm={2}>
            <MDTypography variant="body3" fontWeight="bold">
              {dob.label}
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatePicker
              name={`child1.dob`}
              placeholder="Select Date"
              format="yyyy-MM-dd"
              value={selectedDOB}
              onChange={(selectedDate) => {
                setSelectedDOB(selectedDate);
                selectedDate
                  ? setFieldValue(dob.name, selectedDate)
                  : setFieldValue(dob.name, null);
              }}
            />
            <FormTextFieldFast
              form={mainForm}
              field={dob}
              variant="standard"
              disabled={true}
              hideLabel={true}
              hideField={true}
            />
          </Grid>
          <Grid item xs={12} sm={4}></Grid>
        </Grid>
      </MDBox>
      <Dialog open={open} onClose={closeDialog}>
        <DialogTitle textAlign="center">ALERT!</DialogTitle>
        <DialogContent dividers>
          <MDBox
            borderRadius="lg"
            sx={{ p: 1, mb: 2, border: "1px solid red" }}
          >
            <MDBox>
              <MDTypography variant="body2" fontWeight="medium">
                Invalid Mobile Number
              </MDTypography>
            </MDBox>
            <MDBox>Please enter a valid mobile number to proceed.</MDBox>
          </MDBox>
        </DialogContent>
        <DialogActions>
          <MDButton
            size="small"
            variant="gradient"
            color="success"
            onClick={closeDialog}
          >
            OKAY
          </MDButton>
        </DialogActions>
      </Dialog>
      <Dialog open={openFailToSendDialog} onClose={closeFailToSendDialog}>
        <DialogTitle textAlign="center">ALERT!</DialogTitle>
        <DialogContent dividers>
          <MDBox
            borderRadius="lg"
            sx={{ p: 1, mb: 2, border: "1px solid red" }}
          >
            <MDBox>
              <MDTypography variant="body2" fontWeight="medium">
                Fail To Send
              </MDTypography>
            </MDBox>
            <MDBox>WhatsApp sending is unsuccessful.</MDBox>
          </MDBox>
        </DialogContent>
        <DialogActions>
          <MDButton
            size="small"
            variant="gradient"
            color="success"
            onClick={closeFailToSendDialog}
          >
            OKAY
          </MDButton>
        </DialogActions>
      </Dialog>
    </MDBox>
  );
}

export default EditParticipant;
