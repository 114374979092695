import { createContext, useContext, useReducer } from "react";
// import {
//   subscribeAuthState,
//   getCurrentUserRoles,
// } from "services/authentication";

// import { useDocument } from "hooks/useDocument";
// import { useLogManager } from "hooks/useLogManager";

import PropTypes from "prop-types";

const AuthContext = createContext();
AuthContext.displayName = "AuthContext";

const authReducer = (state, action) => {
  const userState = action?.payload?.user;
  const rolesState = action?.payload?.roles;
  const displayNameState = userState?.displayName;
  const displayNameKeyState = displayNameState
    ?.replaceAll(" ", "-")
    .toLowerCase();
  const sessionIdState = action?.payload?.sessionId;

  switch (action.type) {
    case "SIGNIN":
      return {
        ...state,
        user: userState,
        roles: rolesState,
        displayName: displayNameState,
        displayNameKey: displayNameKeyState,
        sessionId: sessionIdState,
      };
    case "SIGNOUT":
      return {
        ...state,
        user: null,
        roles: null,
        displayName: null,
        displayNameKey: null,
        sessionId: null,
      };
    // case "AUTH_IS_READY":
    //   return {
    //     user: userState,
    //     roles: rolesState,
    //     displayName: displayNameState,
    //     displayNameKey: displayNameKeyState,
    //     sessionId: sessionIdState,
    //     authIsReady: true,
    //   };
    default:
      return state;
  }
};

const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, {
    user: null,
    roles: null,
    displayName: null,
    displayNameKey: null,
    sessionId: null,
    // authIsReady: false,
  });

  // const { updateDoc, serverTimestamp } = useDocument();
  // const { logUserActivity } = useLogManager();

  // useEffect(() => {
  //   const unsubscribe = subscribeAuthState(async (user) => {
  //     if (user) {
  //       await updateDoc("users", user.uid, {
  //         detectedAt: serverTimestamp(),
  //         detectedBy: "system",
  //       });

  //       await logUserActivity({
  //         uid: user.uid,
  //         activity: "activity detected",
  //         document: null,
  //         timestamp: serverTimestamp(),
  //       });
  //     }
  //     const roles = await getCurrentUserRoles();
  //     dispatch({
  //       type: "AUTH_IS_READY",
  //       payload: { user: user, roles: roles },
  //     });
  //   });
  //   // unsubscribe on unmount
  //   return () => unsubscribe();
  // }, [logUserActivity, serverTimestamp, updateDoc]);

  return (
    <AuthContext.Provider value={{ ...state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuthContext = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw Error("useAuthContext must be used inside an AuthContextProvider");
  }

  return context;
};

AuthContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { AuthContextProvider, useAuthContext };
