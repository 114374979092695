import { useState } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// react-router components
import { Link } from "react-router-dom";

// @mui material components
import Menu from "@mui/material/Menu";
// import Grid from "@mui/material/Grid";

import MDBox from "components/atoms/MDBox";
import MDButton from "components/atoms/MDButton";

import DefaultNavbarLink from "components/organisms/Navbars/DefaultNavbar/DefaultNavbarLink";

// DefaultNavbar dropdown menus
import PagesMenu from "components/organisms/Navbars/DefaultNavbar/Menus/PagesMenu";
import AuthenticationMenu from "components/organisms/Navbars/DefaultNavbar/Menus/AuthenticationMenu";
import ApplicationsMenu from "components/organisms/Navbars/DefaultNavbar/Menus/ApplicationsMenu";
import EcommerceMenu from "components/organisms/Navbars/DefaultNavbar/Menus/EcommerceMenu";
import DocsMenu from "components/organisms/Navbars/DefaultNavbar/Menus/DocsMenu";

function DefaultNavbarMobile({ routes, open, close, action }) {
  const { width } = open && open.getBoundingClientRect();
  const [openCollapse, setOpenCollapse] = useState(false);

  const handleSepOpenCollapse = (name) =>
    openCollapse === name ? setOpenCollapse(false) : setOpenCollapse(name);

  return (
    <Menu
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      anchorEl={open}
      open={Boolean(open)}
      onClose={close}
      MenuListProps={{ style: { width: `calc(${width}px - 4rem)` } }}
    >
      <MDBox px={0.5}>
        <DefaultNavbarLink
          name="pages"
          collapseStatus={openCollapse === "pages"}
          onClick={() => handleSepOpenCollapse("pages")}
        >
          <MDBox maxHeight="16rem" overflow="auto">
            <PagesMenu routes={routes} mobileMenu />
          </MDBox>
        </DefaultNavbarLink>
        <DefaultNavbarLink
          name="authentication"
          collapseStatus={openCollapse === "authentication"}
          onClick={() => handleSepOpenCollapse("authentication")}
        >
          <MDBox maxHeight="16rem" overflow="auto">
            <AuthenticationMenu routes={routes} mobileMenu />
          </MDBox>
        </DefaultNavbarLink>
        <DefaultNavbarLink
          name="applications"
          collapseStatus={openCollapse === "applications"}
          onClick={() => handleSepOpenCollapse("applications")}
        >
          <MDBox maxHeight="16rem" overflow="auto">
            <ApplicationsMenu routes={routes} mobileMenu />
          </MDBox>
        </DefaultNavbarLink>
        <DefaultNavbarLink
          name="ecommerce"
          collapseStatus={openCollapse === "ecommerce"}
          onClick={() => handleSepOpenCollapse("ecommerce")}
        >
          <MDBox maxHeight="16rem" overflow="auto">
            <EcommerceMenu routes={routes} mobileMenu />
          </MDBox>
        </DefaultNavbarLink>
        <DefaultNavbarLink
          name="docs"
          collapseStatus={openCollapse === "docs"}
          onClick={() => handleSepOpenCollapse("docs")}
        >
          <MDBox maxHeight="16rem" overflow="auto">
            <DocsMenu routes={routes} mobileMenu />
          </MDBox>
        </DefaultNavbarLink>
        {action &&
          (action.type === "internal" ? (
            <MDBox mx={1} my={1}>
              <MDButton
                fullWidth
                component={Link}
                to={action.route}
                variant="outlined"
                color={action.color ? action.color : "dark"}
                size="small"
              >
                {action.label}
              </MDButton>
            </MDBox>
          ) : (
            <MDBox>
              <MDButton
                fullWidth
                component="a"
                href={action.route}
                target="_blank"
                rel="noreferrer"
                variant="gradient"
                color={action.color ? action.color : "dark"}
                size="small"
                sx={{ mt: -0.3 }}
              >
                {action.label}
              </MDButton>
            </MDBox>
          ))}
      </MDBox>
    </Menu>
  );
}

// Typechecking props for the DefaultNavbarMenu
DefaultNavbarMobile.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  open: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
  close: PropTypes.oneOfType([PropTypes.func, PropTypes.bool, PropTypes.object])
    .isRequired,
  action: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      type: PropTypes.oneOf(["external", "internal"]).isRequired,
      route: PropTypes.string.isRequired,
      color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "dark",
        "light",
      ]),
      label: PropTypes.string.isRequired,
    }),
  ]),
};

export default DefaultNavbarMobile;
