import PropTypes from "prop-types";

import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";

import { ErrorMessage, FastField } from "formik";
import DebounceTextFieldWrapper from "components/molecules/Formik/Wrapper/DebounceTextFieldWrapper";

function FormTextFieldFast({
  form,
  field,
  defaultValue = "",
  onChange,
  disabled,
  hideLabel,
  hideStatus,
  hideField,
  notTouchable,
  ...rest
}) {
  const { values, errors, touched, handleChange } = form;
  const value = values[field.name] ?? defaultValue;
  const error = disabled
    ? false
    : !!errors[field.name] && (notTouchable ? true : touched[field.name]);
  const success = disabled
    ? false
    : !!values[field.name] &&
      values[field.name].length > 0 &&
      !errors[field.name];
  return (
    <MDBox mb={2}>
      <FastField
        name={field.name}
        type={field.type}
        label={hideLabel ? "" : field.label}
        value={value}
        onChange={onChange ?? handleChange}
        as={DebounceTextFieldWrapper}
        placeholder={field.placeholder}
        disabled={disabled}
        error={hideStatus ? false : error}
        success={hideStatus ? false : success}
        sx={hideField ? { display: "none" } : {}}
        {...rest}
      />
      <MDBox mt={0.75}>
        <MDTypography
          component="div"
          variant="caption"
          color="error"
          fontWeight="regular"
          sx={{ whiteSpace: "pre-wrap" }}
        >
          <ErrorMessage name={field.name}></ErrorMessage>
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of FormTextFieldFast
FormTextFieldFast.defaultProps = {
  hideLabel: false,
  hideStatus: false,
  notTouchable: false,
};

// typechecking props for FormTextFieldFast
FormTextFieldFast.propTypes = {
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  variant: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  hideLabel: PropTypes.bool,
  hideStatus: PropTypes.bool,
  children: PropTypes.node,
};

export default FormTextFieldFast;
