import { useEffect, useCallback, useState, useReducer, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useAuthContext } from "context/AuthContext";

import { useLogManager } from "hooks/useLogManager";
import { useDocument } from "hooks/useDocument";

import { Permission } from "models/abac";
import { useAbac } from "react-abac";

import validations from "pages/nudges/groupA/schemas/validations";
import initialValues from "pages/nudges/groupA/schemas/initialValues";

const collectionPathFormNudges = "formnudges";
const initialState = {
  data: initialValues,
  isPending: false,
  error: null,
  success: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "DISMISS":
      return {
        isPending: false,
        data: initialValues,
        success: null,
        error: null,
      };
    case "IS_PENDING":
      return {
        isPending: true,
        data: initialValues,
        success: null,
        error: null,
      };
    case "INITIAL":
      return {
        isPending: false,
        data: action.payload,
        success: null,
        error: null,
      };
    case "UPDATED_FORMNUDGES":
      return {
        isPending: false,
        data: action.payload,
        success: `Updated form nudges successfully.`,
        error: null,
      };
    case "CREATED_NEW_FORMNUDGE":
      return {
        isPending: false,
        data: action.payload,
        success: `Created a new form nudge.`,
        error: null,
      };
    case "DELETED_FORMNUDGE":
      return {
        isPending: false,
        data: action.payload,
        success: `Deleted a form nudge from the record.`,
        error: null,
      };
    case "ERROR":
      return {
        isPending: false,
        data: initialValues,
        success: null,
        error: action.error,
      };
    default:
      return state;
  }
};

export const useFormNudgeManager = () => {
  const [response, dispatch] = useReducer(reducer, initialState);
  const [isUnmounted, setIsUnmounted] = useState(false);
  const { pathname } = useLocation();
  const { logUserActivity } = useLogManager();
  const { retrieveDoc, updateDoc, serverTimestamp } = useDocument();
  const [formnudges, setFormNudges] = useState(null);
  const { userHasPermissions } = useAbac();
  const { user } = useAuthContext();
  const formnudgeIndex = useRef(null);
  const updateMode = useRef(null);

  const dispatchIfNotUnmounted = useCallback(
    (action) => {
      if (!isUnmounted) {
        dispatch(action);
      }
    },
    [isUnmounted]
  );

  const dispatchDismiss = useCallback(
    () => dispatchIfNotUnmounted({ type: "DISMISS" }),
    [dispatchIfNotUnmounted]
  );

  const dispatchError = useCallback(
    (err) => {
      console.error(err);
      if (
        !["PermissionDeniedError", "OperationInvalidError"].includes(err.name)
      ) {
        err.message = "The operation couldn't be completed";
        err.name = "OperationIncompleteError";
        // TODO: send error stack to server
      }
      dispatchIfNotUnmounted({
        type: "ERROR",
        error: err,
      });
    },
    [dispatchIfNotUnmounted]
  );

  const validateOperation = useCallback(async () => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      let operationInvalidError = new Error(
        "Invalid Operation. You are not allowed to carry out this activity."
      );
      operationInvalidError.name = "OperationInvalidError";

      if (!pathname.includes("/forms/googleforms")) {
        throw operationInvalidError;
      }

      const retrievedFormNudges = await retrieveDoc(
        collectionPathFormNudges,
        "formsGroupA"
      );

      !!retrievedFormNudges.data?.formnudges
        ? setFormNudges(retrievedFormNudges.data?.formnudges)
        : setFormNudges(null);

      dispatchIfNotUnmounted({
        type: "INITIAL",
        payload: retrievedFormNudges.data,
      });
    } catch (err) {
      dispatchError(err);
    }
  }, [
    dispatchIfNotUnmounted,
    pathname,
    dispatchError,
    retrieveDoc,
    setFormNudges,
  ]);

  useEffect(() => {
    try {
      validateOperation();
    } catch (err) {
      dispatchError(err);
    }
    return () => {
      setIsUnmounted(true);
    };
  }, [dispatchError, validateOperation]);

  const modeValidation = validations;
  const modeFieldDisabled = false;

  let modePermission = Permission.READ_ALL_NUDGES;
  let modeTitle = "Nudge Messages For Forms";

  const submitDelete = async (idx) => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      if (userHasPermissions(Permission.DELETE_NUDGE)) {
        const newFormNudges = [...formnudges];
        newFormNudges.splice(idx, 1);
        setFormNudges(newFormNudges);

        const updatedDoc = await updateDoc(
          collectionPathFormNudges,
          "formsGroupA",
          {
            formnudges: newFormNudges,
            modifiedAt: serverTimestamp(),
            modifiedBy: user.uid,
          }
        );

        await logUserActivity({
          uid: user.uid,
          activity: "delete form nudge [useFormNudgeManager.submitDelete]",
          document: updatedDoc,
          timestamp: serverTimestamp(),
        });

        dispatchIfNotUnmounted({
          type: "DELETED_FORMNUDGE",
          payload: updatedDoc.data,
        });
      } else {
        dispatchIfNotUnmounted({
          type: "ERROR",
          error: "Permission Denied. You are not allowed to update the nudges.",
        });

        let error = new Error(
          "Permission Denied. You are not allowed to update the nudges."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      dispatchError(err);
    }
  };

  const submitUpdate = async (formnudges) => {
    dispatchIfNotUnmounted({ type: "IS_PENDING" });
    try {
      if (userHasPermissions(Permission.UPDATE_NUDGE)) {
        const updatedDoc = await updateDoc(
          collectionPathFormNudges,
          "formsGroupA",
          {
            formnudges: formnudges,
            modifiedAt: serverTimestamp(),
            modifiedBy: user.uid,
          }
        );

        await logUserActivity({
          uid: user.uid,
          activity: "update form nudge [useFormNudgeManager.submitUpdate]",
          document: updatedDoc,
          timestamp: serverTimestamp(),
        });

        console.log(JSON.stringify("submitUpdate"));

        dispatchIfNotUnmounted({
          type: "UPDATED_FORMNUDGES",
          payload: updatedDoc.data,
        });
      } else {
        dispatchIfNotUnmounted({
          type: "ERROR",
          error: "Permission Denied. You are not allowed to edit the nudges.",
        });

        let error = new Error(
          "Permission Denied. You are not allowed to edit the nudges."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      dispatchError(err);
    }
  };

  const submitEdit = async (values) => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      if (userHasPermissions(Permission.UPDATE_NUDGE)) {
        formnudges[formnudgeIndex.current].id = values.id;
        formnudges[formnudgeIndex.current].month = values.month;
        formnudges[formnudgeIndex.current].type = values.type;
        formnudges[formnudgeIndex.current].contents = values.contents;
        setFormNudges(formnudges);

        const updatedDoc = await updateDoc(
          collectionPathFormNudges,
          "formsGroupA",
          {
            formnudges: formnudges,
            modifiedAt: serverTimestamp(),
            modifiedBy: user.uid,
          }
        );

        await logUserActivity({
          uid: user.uid,
          activity: "edit form nudge [useFormNudgeManager.submitEdit]",
          document: updatedDoc,
          timestamp: serverTimestamp(),
        });

        dispatchIfNotUnmounted({
          type: "UPDATED_FORMNUDGES",
          payload: updatedDoc.data,
        });
      } else {
        dispatchIfNotUnmounted({
          type: "ERROR",
          error: "Permission Denied. You are not allowed to edit the nudges.",
        });

        let error = new Error(
          "Permission Denied. You are not allowed to edit the nudges."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      dispatchError(err);
    }
  };

  const submitCreate = async (values) => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      if (userHasPermissions(Permission.CREATE_NUDGE)) {
        formnudges.splice(formnudgeIndex.current + 1, 0, values);
        setFormNudges(formnudges);

        const updatedDoc = await updateDoc(
          collectionPathFormNudges,
          "formsGroupA",
          {
            formnudges: formnudges,
            modifiedAt: serverTimestamp(),
            modifiedBy: user.uid,
          }
        );

        await logUserActivity({
          uid: user.uid,
          activity: "create form nudge [useFormNudgeManager.submitCreate]",
          document: updatedDoc,
          timestamp: serverTimestamp(),
        });

        dispatchIfNotUnmounted({
          type: "CREATED_NEW_FORMNUDGE",
          payload: updatedDoc.data,
        });
      } else {
        dispatchIfNotUnmounted({
          type: "ERROR",
          error: "Permission Denied. You are not allowed to edit the nudges.",
        });

        let error = new Error(
          "Permission Denied. You are not allowed to edit the nudges."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      dispatchError(err);
    }
  };

  return {
    modeTitle,
    modePermission,
    modeValidation,
    modeFieldDisabled,
    response,
    dispatchDismiss,
    dispatchError,
    formnudges,
    setFormNudges,
    submitEdit,
    submitCreate,
    submitDelete,
    submitUpdate,
    formnudgeIndex,
    updateMode,
  };
};
