import { useState } from "react";

import Grid from "@mui/material/Grid";

import MDBox from "components/atoms/MDBox";
import MDButton from "components/atoms/MDButton";

import ErrorSnackbar from "components/molecules/Notifications/ErrorSnackbar";
import SuccessSnackbar from "components/molecules/Notifications/SuccessSnackbar";

import DashboardLayout from "components/templates/DashboardLayout";
import Topnav from "components/organisms/Topnav";
import DashboardFooter from "components/organisms/Footer/DashboardFooter";
import PermissionDenied from "components/organisms/PermissionDenied";
import InvalidOperation from "components/organisms/InvalidOperation";
import IncompleteOperation from "components/organisms/IncompleteOperation";

import SubmissionProgress from "components/organisms/SubmissionProgress";

import Roles from "pages/settings/roles/manage/components/Roles";
import Permissions from "pages/settings/roles/manage/components/Permissions";
import Sidenav from "pages/settings/roles/manage/components/Sidenav";

import { Formik, Form } from "formik";
import form from "pages/settings/roles/manage/schemas/form";

import { AllowedTo } from "react-abac";

import { useSettingsManager } from "pages/settings/roles/hooks/useSettingsManager";

function ManageRoles() {
  const [selectedRole, setSelectedRole] = useState(null);

  const {
    modeTitle,
    modeSubmit,
    modeFieldDisabled,
    modeValidation,
    submitEdit,
    response,
    modePermission,
    dispatchDismiss,
    dispatchError,
  } = useSettingsManager();

  const dispatchDismissAfter = (millisecond) => {
    return new Promise(() => {
      setTimeout(() => {
        dispatchDismiss();
      }, millisecond);
    });
  };

  const { formId, formField } = form;

  const handleSubmit = async (values, actions) => {
    try {
      await submitEdit(selectedRole.id, values);
    } catch (err) {
      dispatchError(err);
      actions.setSubmitting(false);
    } finally {
      actions.setSubmitting(false);
      await dispatchDismissAfter(3000);
    }
  };

  return (
    <DashboardLayout>
      <Topnav title={modeTitle} />
      <AllowedTo perform={modePermission} no={() => <PermissionDenied />}>
        {response.isPending ? (
          <SubmissionProgress />
        ) : (
          <>
            {response.error && (
              <>
                {response.error?.name === "OperationInvalidError" && (
                  <InvalidOperation />
                )}
                {response.error?.name === "OperationIncompleteError" && (
                  <IncompleteOperation />
                )}
                <ErrorSnackbar
                  title={modeTitle}
                  dateTime=""
                  message={response.error?.message}
                  autoDismiss
                />
              </>
            )}
            {response.success && (
              <SuccessSnackbar
                title={modeTitle}
                dateTime=""
                message={response.success}
                autoDismiss
              />
            )}
            <MDBox pt={3}>
              <MDBox>
                {response.error?.name !== "OperationInvalidError" && (
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={2}>
                      <Sidenav />
                    </Grid>

                    <Grid item xs={12} lg={10}>
                      <MDBox mb={3}>
                        <Formik
                          enableReinitialize
                          initialValues={response.data}
                          validationSchema={modeValidation}
                          onSubmit={handleSubmit}
                        >
                          {(formik) => (
                            <Form id={formId} autoComplete="off">
                              <Grid container spacing={3}>
                                <Grid item xs={12}>
                                  <Roles
                                    mainForm={formik}
                                    mainFormField={formField}
                                    modeDisabled={modeFieldDisabled}
                                    dispatchMainError={dispatchError}
                                    selectedRole={selectedRole}
                                    setSelectedRole={setSelectedRole}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <Permissions
                                    mainForm={formik}
                                    mainFormField={formField}
                                    modeDisabled={modeFieldDisabled}
                                    dispatchMainError={dispatchError}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <MDBox
                                    width="100%"
                                    display="flex"
                                    justifyContent="space-between"
                                  >
                                    <MDBox></MDBox>
                                    <MDButton
                                      disabled={formik.isSubmitting}
                                      type="submit"
                                      variant="gradient"
                                      color="dark"
                                    >
                                      {modeSubmit}
                                    </MDButton>
                                  </MDBox>
                                </Grid>
                              </Grid>
                            </Form>
                          )}
                        </Formik>
                      </MDBox>
                    </Grid>
                  </Grid>
                )}
              </MDBox>
            </MDBox>
            <DashboardFooter />
          </>
        )}
      </AllowedTo>
    </DashboardLayout>
  );
}

export default ManageRoles;
