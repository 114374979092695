import { useEffect, useState } from "react";
import { signOutUser } from "services/authentication";
import { useAuthContext } from "context/AuthContext";

import { useLogManager } from "hooks/useLogManager";
import { useDocument } from "hooks/useDocument";

export const useSignout = () => {
  const [isUnmounted, setIsUnmounted] = useState(false);
  const [error, setError] = useState(null);
  const { user, dispatch, sessionId } = useAuthContext();

  const { logUserActivity } = useLogManager();
  const { purgeDoc, serverTimestamp } = useDocument();

  const signout = async () => {
    try {
      setError(null);

      await purgeDoc(`metadata/${user.uid}/session`, sessionId);

      await signOutUser();

      await logUserActivity({
        uid: user.uid,
        activity: "signout",
        document: null,
        timestamp: serverTimestamp(),
      });

      dispatch({ type: "SIGNOUT" });

      if (!isUnmounted) {
        setError(null);
      }
    } catch (err) {
      if (!isUnmounted) {
        // SEE https://cheatsheetseries.owasp.org/cheatsheets/Authentication_Cheat_Sheet.html#authentication-and-error-messages
        // Don't show error on console
        // Respond with generic error message regardless
        // console.error(err.message);
        setError("Sign out failed.");
      }
    }
  };

  useEffect(() => {
    return () => setIsUnmounted(true);
  }, []);

  return { signout, error };
};
