import PropTypes from "prop-types";

import DefaultNavbar from "components/organisms/Navbars/DefaultNavbar";

function HeaderFragment({ logo, routes, action, children }) {
  return (
    <>
      <DefaultNavbar
        logo={logo}
        routes={routes}
        action={action}
        transparent
        light
      />
      {children}
    </>
  );
}

// Typechecking props for the HeaderFragment
HeaderFragment.propTypes = {
  children: PropTypes.node,
};

export default HeaderFragment;
