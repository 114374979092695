import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";

function TermsOfUse({ mainForm, mainFormField }) {
  return (
    <>
      <MDTypography mb={1} variant="h3">
        Terms of use
      </MDTypography>

      <MDTypography mb={2} variant="body2" sx={{ lineHeight: 1.25 }}>
        Please read these terms of use carefully before using this website.
      </MDTypography>
      <MDTypography mb={2} variant="body2" sx={{ lineHeight: 1.25 }}>
        By using this website, you signify that you have read and accepted these
        terms of use. If you (the “user”) do not agree to these terms of use, do
        not access or use the website. The user is responsible for compliance
        with all applicable laws and regulations in any jurisdiction.
      </MDTypography>

      <MDTypography mb={2} variant="h4" sx={{ lineHeight: 1.25 }}>
        Restrictions on use of materials
      </MDTypography>
      <MDTypography mb={2} variant="body2" sx={{ lineHeight: 1.25 }}>
        This website and its contents are owned and operated by Vixel Inc Pte.
        Ltd. (hereinafter referred to as “Vixel”) and all rights hereunder
        belong to Vixel. All company names and logos and all related products
        and service names, design marks and slogans are trademarks and service
        marks and are the properties of their respective owners and may not be
        reproduced or appropriated in any manner without the written permission
        of their respective owners.
      </MDTypography>
      <MDTypography mb={2} variant="body2" sx={{ lineHeight: 1.25 }}>
        This website and its contents are owned and operated by Vixel Inc Pte.
        Ltd. (hereinafter referred to as “Vixel”) and all rights hereunder
        belong to Vixel. All company names and logos and all related products
        and service names, design marks and slogans are trademarks and service
        marks and are the properties of their respective owners and may not be
        reproduced or appropriated in any manner without the written permission
        of their respective owners.
      </MDTypography>
      <MDTypography mb={2} variant="body2" sx={{ lineHeight: 1.25 }}>
        No material, graphic or image from this website may be appropriated or
        modified in any manner, or reproduced, republished, uploaded, posted,
        transmitted or distributed in any way, without the prior written
        permission of Vixel.
      </MDTypography>
      <MDTypography mb={2} variant="body2" sx={{ lineHeight: 1.25 }}>
        No party is permitted to establish links to this website without prior
        written permission from Vixel. We reserve all rights to deny permission
        for any such links and we are not under any obligation to establish
        reciprocal links with any third party. Nothing contained herein confers
        any license or right under any copyright, patent, trademark or other
        proprietary rights of Vixel or any third party.
      </MDTypography>
      <MDTypography mb={2} variant="body2" sx={{ lineHeight: 1.25 }}>
        Neither Vixel, any of its affiliates, subsidiaries, directors,
        employees, agents, partners, consultants and representatives nor any
        other person is, in connection with this site, engaged in rendering
        auditing, accounting, tax, legal, advisory, consulting or other
        professional services or advice. Each user agrees not to use the content
        of this website as a substitute for independent investigations and
        competent financial and business judgment of the user of this website
        and such user shall obtain professional advice tailored to his
        particular factual situation. Each user is responsible for all matters
        arising from its use of this website.
      </MDTypography>
      <MDTypography mb={2} variant="body2" sx={{ lineHeight: 1.25 }}>
        Each user agrees to use this website in accordance with these terms of
        use and for lawful and proper purposes and shall not:
      </MDTypography>
      <MDBox display="flex">
        <MDTypography mb={2} mr={5} variant="body2" sx={{ lineHeight: 1.25 }}>
          a.
        </MDTypography>
        <MDTypography mb={2} variant="body2" sx={{ lineHeight: 1.25 }}>
          upload, post, email, transmit or otherwise make available any content
          that is unlawful, harmful, threatening, abusive, harassing, tortious,
          defamatory, obscene, invasive of another’s privacy or otherwise
          inappropriate, offensive, objectionable or which creates liability on
          Vixel’s part;
        </MDTypography>
      </MDBox>

      <MDBox display="flex">
        <MDTypography mb={2} mr={5} variant="body2" sx={{ lineHeight: 1.25 }}>
          b.
        </MDTypography>
        <MDTypography mb={2} variant="body2" sx={{ lineHeight: 1.25 }}>
          upload, post, email, transmit or otherwise make available any
          unsolicited or unauthorised advertising, promotional materials, “junk
          mail,” “spam”, “chain letters,” “pyramid schemes,” or any other form
          of solicitation;
        </MDTypography>
      </MDBox>

      <MDBox display="flex">
        <MDTypography mb={2} mr={5} variant="body2" sx={{ lineHeight: 1.25 }}>
          c.
        </MDTypography>
        <MDTypography mb={2} variant="body2" sx={{ lineHeight: 1.25 }}>
          upload, post, email, transmit or otherwise make available any material
          that contains software viruses or any other computer code, files or
          programs designed to interrupt, destroy, interfere with or limit the
          functionality of any computer software or hardware or
          telecommunications equipment; or
        </MDTypography>
      </MDBox>

      <MDBox display="flex">
        <MDTypography mb={2} mr={5} variant="body2" sx={{ lineHeight: 1.25 }}>
          d.
        </MDTypography>
        <MDTypography mb={2} variant="body2" sx={{ lineHeight: 1.25 }}>
          interfere or attempt to interfere with the operation or functionality
          of this website; or obtain or attempt to obtain unauthorised access,
          via whatever means, to any of Vixel’s systems.
        </MDTypography>
      </MDBox>

      <MDTypography mb={2} variant="body2" sx={{ lineHeight: 1.25 }}>
        Third party postings and hyperlinks to third party websites This website
        may contain hyperlinks to other websites (“External Sites”) which are
        neither maintained nor controlled by Vixel, or may contain content
        posted on or via the website by third parties. Vixel shall not be
        responsible for any errors or omissions in any content in the website,
        or the content, products or services of any hyperlinked External Site or
        any hyperlink contained in a hyperlinked External Site, nor for the
        privacy and security practices employed by these External Sites, and
        under no circumstances shall Vixel be liable for any loss or damage of
        any kind incurred as a result of the use of any content posted or
        contained in e-mails or otherwise transmitted or displayed via the
        website, or arising from access to External Sites. Use of the website
        and any hyperlinks and access to External Sites are entirely at the
        user’s own risk.
      </MDTypography>

      <MDTypography mb={2} variant="body2" sx={{ lineHeight: 1.25 }}>
        The user acknowledges that Vixel has no control over and excludes all
        liability for any material on the Internet which can be accessed by
        using this website. Neither will Vixel be deemed to have endorsed any
        such content thereto.
      </MDTypography>

      <MDTypography mb={2} variant="h4" sx={{ lineHeight: 1.25 }}>
        Use of cookies
      </MDTypography>
      <MDTypography mb={2} variant="body2" sx={{ lineHeight: 1.25 }}>
        Vixel takes certain industry-accepted precautions to secure the website
        or portions thereof. However, the user understands and agrees that such
        precautions cannot guarantee that use of the website is invulnerable to
        security breaches, nor does Vixel make any warranty, guarantee, or
        representation that use of the website is protected from all viruses,
        worms, bugs, Trojan horses and other vulnerabilities.
      </MDTypography>
      <MDTypography mb={2} variant="body2" sx={{ lineHeight: 1.25 }}>
        During the user’s use of the website, Vixel may issue to and request
        from the user’s computer “cookies” to enable more convenient browsing
        when the user revisits the website. The user must not alter any cookies
        sent to his/her computer from the website and must ensure that the
        user’s computer sends correct and accurate cookies in response to any
        relevant request from the website.
      </MDTypography>

      <MDTypography mb={2} variant="h4" sx={{ lineHeight: 1.25 }}>
        No representation or warranties
      </MDTypography>
      <MDTypography mb={2} variant="body2" sx={{ lineHeight: 1.25 }}>
        While every effort has been made to ensure that the information provided
        in this website herein is up to date and accurate, Vixel and its
        affiliates, subsidiaries, directors, employees, agents, partners,
        consultants and representatives make no representation or warranty of
        any kind either express or implied as to the completeness, correctness,
        accuracy, suitability, reliability or otherwise of the website or its
        content in this website or the results of its use for any purpose, all
        of which is provided “as is” and “as available”. They do not warrant
        that this website or this server that makes it available is free of any
        virus or other harmful elements.
      </MDTypography>
      <MDTypography mb={2} variant="body2" sx={{ lineHeight: 1.25 }}>
        Vixel and its affiliates, subsidiaries, directors, employees, agents,
        partners, consultants and representatives disclaim all warranties and
        obligations relating to this website, including but not limited to all
        implied warranties and obligations of merchantability, fitness for a
        particular purpose, title and non-infringement.
      </MDTypography>

      <MDTypography mb={2} variant="h4" sx={{ lineHeight: 1.25 }}>
        Limitation of liability
      </MDTypography>
      <MDTypography mb={2} variant="body2" sx={{ lineHeight: 1.25 }}>
        The information contained herein in this website shall be accessed and
        used at the user’s own risk and to the fullest extent permissible and
        subject and pursuant to all applicable laws and regulations. Vixel and
        its affiliates, subsidiaries, directors, employees, agents, partners,
        consultants and representatives shall not be liable for any direct,
        indirect, incidental, special, exemplary, consequential or other damages
        whatsoever, (including but not limited to liability for loss of use,
        data or profits), including but not limited to contract, negligence or
        other tortious actions, arising out of or in connection with this site,
        even if any of Vixel and its affiliates, subsidiaries, directors,
        employees, agents, partners, consultants and representatives has been
        advised of the possibility of such damages or losses that were, are
        being or will be incurred.
      </MDTypography>

      <MDTypography mb={2} variant="h4" sx={{ lineHeight: 1.25 }}>
        Modifications
      </MDTypography>
      <MDTypography mb={2} variant="body2" sx={{ lineHeight: 1.25 }}>
        Vixel reserves all rights to make any and all changes in this website at
        its sole discretion without prior notice to the user. Vixel reserves the
        right to deny access to this website to anyone at any time without prior
        notice. Materials on our website may include technical inaccuracies or
        typographical errors. Changes may be periodically incorporated into this
        material. Vixel may make improvements and/or changes in the products,
        services and/or programs described in these materials at any time
        without notice.
      </MDTypography>

      <MDTypography mb={2} variant="h4" sx={{ lineHeight: 1.25 }}>
        Suggestions, comments and feedback
      </MDTypography>
      <MDTypography mb={2} variant="body2" sx={{ lineHeight: 1.25 }}>
        Should the user respond to any part of the materials contained herein in
        this website with any communications including feedback data, such as
        questions, comments, suggestions, or the like, such information shall be
        deemed to be non-confidential and Vixel and its affiliates,
        subsidiaries, directors, employees, agents, partners, consultants and
        representatives shall have no obligation whatsoever with respect to such
        communications and shall be free to reproduce, use, disclose and
        distribute the information to others without limitation, and shall be
        free to use in any way for any purpose whatsoever the content of such
        communications including any ideas, know-how, techniques or concepts
        disclosed therein.
      </MDTypography>

      <MDTypography mb={2} variant="h4" sx={{ lineHeight: 1.25 }}>
        Applicable law and jurisdiction
      </MDTypography>
      <MDTypography mb={2} variant="body2" sx={{ lineHeight: 1.25 }}>
        The law applicable to the terms of use of this website is the law of the
        Republic of Singapore and the courts of the Republic of Singapore will
        have exclusive jurisdiction in case of any dispute.
      </MDTypography>
    </>
  );
}

export default TermsOfUse;
