import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";

import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";

function CoverDesign({ coverHeight, image, mainTitle, subTitle, children }) {
  return (
    <>
      <MDBox
        position="relative"
        minHeight="30vh"
        height={coverHeight}
        borderRadius="xl"
        m={2}
        pt={2}
        sx={{
          backgroundImage: ({
            functions: { linearGradient, rgba },
            palette: { gradients },
          }) =>
            image &&
            `${linearGradient(
              rgba(gradients.dark.main, 0),
              rgba(gradients.dark.state, 0.4)
            )}, url(${image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Grid
          container
          justifyContent="center"
          sx={{ position: "relative", pt: 20, textAlign: "center" }}
        >
          <Grid item xs={11} lg={5}>
            {mainTitle && (
              <MDBox mb={1}>
                <MDTypography variant="h2" color="white" fontWeight="bold">
                  {mainTitle}
                </MDTypography>
              </MDBox>
            )}
            {subTitle && (
              <MDBox mb={2}>
                <MDTypography variant="body2" color="white" fontWeight="light">
                  {subTitle}
                </MDTypography>
              </MDBox>
            )}
          </Grid>
        </Grid>
      </MDBox>
      {children}
    </>
  );
}

CoverDesign.defaultProps = {
  coverHeight: "50vh",
};

// Typechecking props for the CoverDesign
CoverDesign.propTypes = {
  coverHeight: PropTypes.string,
  image: PropTypes.string.isRequired,
};

export default CoverDesign;
