// Don't handle exception in this React Hook to allow exception bubbling
// Handle exception at upwards level:
//   send error log back to server
//   show generic error message on snackbar to inform user to report to admin

import { useEffect, useState, useRef } from "react";
import { subscribeCollectionSnapshot } from "services/firestore";

import _ from "lodash";

export const useCollectionSnapshot = (collectionPath, collectionQuery) => {
  const [collectionData, setCollectionData] = useState([]);

  // if we don't use a ref --> infinite loop in useEffect
  // _query is an array and is "different" on every function call
  const collectionQueryRef = useRef(collectionQuery);

  useEffect(() => {
    const unsubscribe = subscribeCollectionSnapshot(
      collectionPath,
      collectionQueryRef.current,
      (snapshot) => {
        let results = [];
        snapshot.docs.forEach((doc) => {
          results.push({ id: doc.id, data: doc.data() });
        });

        setCollectionData(_.cloneDeep(results));
      }
    );

    // cleanup on unmount
    return () => {
      unsubscribe && unsubscribe();
    };
  }, [collectionPath]);

  return { collectionData };
};
