import { useEffect, useCallback, useState, useReducer } from "react";
import { useLocation } from "react-router-dom";
import { useAuthContext } from "context/AuthContext";

import { useLogManager } from "hooks/useLogManager";
import { useDocument } from "hooks/useDocument";

import { useCollectionSnapshot } from "hooks/useCollectionSnapshot";

import { useAbac } from "react-abac";
import { Permission } from "models/abac";

import moment from "moment";
import initialValues from "pages/participants/new/schemas/initialValues";
import validations from "pages/participants/new/schemas/validations";

const collectionPathParents = "parents";
const collectionPathChildren = "children";
const collectionPathNudges = "nudges";
const collectionPathFormNudges = "formnudges";

const initialState = {
  data: initialValues,
  isPending: false,
  error: null,
  success: null,
};

const birthOrders = [
  "first",
  "second",
  "third",
  "fourth",
  "fifth",
  "sixth",
  "seventh",
  "eight",
  "ninth",
  "tenth",
];

const reducer = (state, action) => {
  switch (action.type) {
    case "DISMISS":
      return {
        isPending: false,
        data: initialValues,
        success: null,
        error: null,
      };
    case "IS_PENDING":
      return {
        isPending: true,
        data: initialValues,
        success: null,
        error: null,
      };
    case "INITIAL_USER":
      return {
        isPending: false,
        data: action.payload,
        success: null,
        error: null,
      };
    case "RETRIEVED_USER":
      return {
        isPending: false,
        data: action.payload,
        success: null,
        error: null,
      };
    case "CREATED_USER":
      return {
        isPending: false,
        data: action.payload,
        success: `Successfully created participant, ${action.payload.userName}.`,
        error: null,
      };
    case "ERROR":
      return {
        isPending: false,
        data: initialValues,
        success: null,
        error: action.error,
      };
    default:
      return state;
  }
};

export const useNewParticipantManager = () => {
  const [response, dispatch] = useReducer(reducer, initialState);
  const [isUnmounted, setIsUnmounted] = useState(false);

  const { user } = useAuthContext();

  const { userHasPermissions } = useAbac();

  const { logUserActivity } = useLogManager();
  const { createDoc, retrieveDoc, updateDoc, serverTimestamp } = useDocument();

  const { pathname } = useLocation();

  const dispatchIfNotUnmounted = useCallback(
    (action) => {
      if (!isUnmounted) {
        dispatch(action);
      }
    },
    [isUnmounted]
  );

  const dispatchDismiss = useCallback(
    () => dispatchIfNotUnmounted({ type: "DISMISS" }),
    [dispatchIfNotUnmounted]
  );

  const dispatchError = useCallback(
    (err) => {
      console.error(err);
      if (
        !["PermissionDeniedError", "OperationInvalidError"].includes(err.name)
      ) {
        err.message = "The operation couldn't be completed";
        err.name = "OperationIncompleteError";
        // TODO: send error stack to server
      }
      dispatchIfNotUnmounted({
        type: "ERROR",
        error: err,
      });
    },
    [dispatchIfNotUnmounted]
  );

  const validateOperation = useCallback(async () => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      let operationInvalidError = new Error(
        "Invalid Operation. You are not allowed to carry out this activity."
      );
      operationInvalidError.name = "OperationInvalidError";

      if (!pathname.includes("/participants/new")) {
        throw operationInvalidError;
      }

      dispatchIfNotUnmounted({
        type: "INITIAL_USER",
        payload: initialValues,
      });
    } catch (err) {
      dispatchError(err);
    }
  }, [dispatchIfNotUnmounted, pathname, dispatchError]);

  const [nudgesGroupA, setNudgesGroupA] = useState(null);
  const [nudgesGroupB, setNudgesGroupB] = useState(null);
  const [formNudges, setFormNudges] = useState(null);

  const loadNudgeRecords = useCallback(async () => {
    try {
      const retrievedRecordA = await retrieveDoc(
        collectionPathNudges,
        "GroupA"
      );
      setNudgesGroupA(retrievedRecordA);
      const retrievedRecordB = await retrieveDoc(
        collectionPathNudges,
        "GroupB"
      );
      setNudgesGroupB(retrievedRecordB);
      const retrievedFormNudges = await retrieveDoc(
        collectionPathFormNudges,
        "formsGroupA"
      );
      setFormNudges(retrievedFormNudges);
    } catch (err) {
      dispatchError(err);
    }
  }, [
    retrieveDoc,
    setNudgesGroupA,
    setNudgesGroupB,
    setFormNudges,
    dispatchError,
  ]);

  useEffect(() => {
    try {
      validateOperation();
      loadNudgeRecords();
    } catch (err) {
      dispatchError(err);
    }
    return () => {
      setIsUnmounted(true);
    };
  }, [dispatchError, validateOperation, loadNudgeRecords]);

  let modeTitle = "New Participant";
  let modeSubmit = "Create";
  let modeFieldDisabled = false;
  let modePermission = Permission.READ_ALL_PARTICIPANTS;
  let modeValidation = validations;

  const getNudgeContents = (dob, nudges) => {
    const nudgesWithSendingDates = nudges.map((nudge) => {
      const days = parseInt(nudge.month) * 30;
      return {
        contents: nudge.contents,
        id: nudge.id,
        month: nudge.month,
        type: nudge.type,
        date: moment(dob).add(days, "days").toDate(),
        whatsappStatus: "To send",
      };
    });

    return nudgesWithSendingDates;
  };

  const getFormNudgeContents = (dob, formnudges) => {
    const formnudgesWithSendingDates = formnudges.map((formnudge) => {
      const days = parseInt(formnudge.month) * 30;
      const today = new Date(Date.now());
      return formnudge.sendOnEnrol &&
        formnudge.type !== "Upon Enrolment - GAD-7"
        ? {
            contents: formnudge.contents,
            date: today,
            id: formnudge.id,
            month: formnudge.month,
            sendOnEnrol: formnudge.sendOnEnrol,
            type: formnudge.type,
            whatsappStatus: "To send",
          }
        : formnudge.sendOnEnrol && formnudge.type === "Upon Enrolment - GAD-7"
        ? {
            contents: formnudge.contents,
            date: today,
            id: formnudge.id,
            month: formnudge.month,
            sendOnEnrol: formnudge.sendOnEnrol,
            type: formnudge.type,
            whatsappStatus: "Pending submission of PHQ-9",
          }
        : {
            contents: formnudge.contents,
            date: moment(dob).add(days, "days").toDate(),
            id: formnudge.id,
            month: formnudge.month,
            sendOnEnrol: formnudge.sendOnEnrol,
            type: formnudge.type,
            whatsappStatus: "To send",
          };
    });
    return formnudgesWithSendingDates;
  };

  const createChildrenDocuments = async (children, createdParentDoc) => {
    const createdChildrenIDs = Promise.all(
      children.map(async (child, index) => {
        const nudges =
          child.participantGroup === "Group A"
            ? getNudgeContents(child.dob, nudgesGroupA.data.nudges)
            : child.participantGroup === "Group B"
            ? getNudgeContents(child.dob, nudgesGroupB.data.nudges)
            : null;

        const formnudges = getFormNudgeContents(
          child.dob,
          formNudges.data.formnudges
        );

        const createdChildDoc = await createDoc(
          collectionPathChildren,
          {
            dob: child.dob,
            nudges: nudges,
            formnudges: formnudges,
            participantGroup: child.participantGroup,
            parentId: createdParentDoc.id,
            birthOrder: birthOrders[index],
            totalNumberChildren: children.length,
          },
          user.uid
        );
        return createdChildDoc.id;
      })
    );
    return createdChildrenIDs;
  };

  const submitNew = async (values, continueURL) => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      if (
        userHasPermissions([
          Permission.PERFORM_ADMIN_ACTION,
          Permission.CREATE_PARENT,
        ])
      ) {
        const createdParentDoc = await createDoc(
          collectionPathParents,
          {
            userName: values.userName,
            mobile: values.mobile,
            children: values.children,
          },
          user.uid
        );

        const createdChildrenIDs = await createChildrenDocuments(
          values.children,
          createdParentDoc
        );

        const updatedDoc = await updateDoc(
          collectionPathParents,
          createdParentDoc.id,
          {
            childrenIds: createdChildrenIDs,
            modifiedAt: serverTimestamp(),
            modifiedBy: user.uid,
          }
        );

        await logUserActivity({
          uid: user.uid,
          activity: "create participant [useNewParticipantManager.submitNew]",
          document: updatedDoc,
          timestamp: serverTimestamp(),
        });

        dispatchIfNotUnmounted({
          type: "CREATED_USER",
          payload: createdParentDoc.data,
        });
      } else {
        let error = new Error(
          "Permission Denied. You are not allowed to update user."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      dispatchError(err);
    }
  };

  const parentsQueries = {
    whereQueries: [
      {
        field: "deletedAt",
        condition: "==",
        value: null,
      },
    ],
  };

  const { collectionData: parentsData } = useCollectionSnapshot(
    "parents",
    parentsQueries
  );

  return {
    modeTitle,
    modeSubmit,
    modeFieldDisabled,
    modePermission,
    modeValidation,
    submitNew,
    response,
    dispatchDismiss,
    dispatchError,
    parentsData,
  };
};
