import PropTypes from "prop-types";

import { useCallback, useEffect, useState } from "react";

import MDInput from "components/atoms/MDInput";

import { useDebouncedCallback } from "use-debounce";

const INPUT_DELAY = 500;

function DebounceTextFieldWrapper({ value, onChange, ...rest }) {
  const [innerValue, setInnerValue] = useState("");

  useEffect(() => {
    if (value) {
      setInnerValue(value);
    } else {
      setInnerValue("");
    }
  }, [value]);

  const debouncedHandleOnChange = useDebouncedCallback((event) => {
    if (onChange) {
      onChange(event);
    }
  }, INPUT_DELAY);

  const handleOnChange = useCallback(
    (event) => {
      event.persist();

      const newValue = event.target.value;
      setInnerValue(newValue);
      debouncedHandleOnChange(event);
    },
    [debouncedHandleOnChange]
  );

  return (
    <MDInput value={innerValue} onChange={handleOnChange} fullWidth {...rest} />
  );
}

// Setting default values for the props of DebounceTextFieldWrapper
DebounceTextFieldWrapper.defaultProps = {
  label: "",
};

// typechecking props for DebounceTextFieldWrapper
DebounceTextFieldWrapper.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  variant: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  success: PropTypes.bool,
  children: PropTypes.node,
};

export default DebounceTextFieldWrapper;
