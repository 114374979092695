import ErrorIcon from "@mui/icons-material/Error";
import Backdrop from "@mui/material/Backdrop";

import MDAlert from "components/atoms/MDAlert";
import MDTypography from "components/atoms/MDTypography";
import MDButton from "components/atoms/MDButton";

import { useNavigate } from "react-router-dom";

function InvalidOperation() {
  const navigate = useNavigate();
  return (
    <Backdrop
      sx={{
        color: "#fff",
        backdropFilter: "blur(5px)",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={true}
    >
      <MDAlert color="warning">
        <ErrorIcon fontSize="large" />
        <MDTypography mx={2} variant="body1" color="white">
          Invalid Operation.
        </MDTypography>
        <MDButton variant="outlined" color="white" onClick={() => navigate(-1)}>
          Back
        </MDButton>
      </MDAlert>
    </Backdrop>
  );
}

export default InvalidOperation;
