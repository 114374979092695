import { createContext, useContext, useEffect, useReducer } from "react";

import PropTypes from "prop-types";

import theme from "assets/theme";
import themeDark from "assets/theme-dark";

const MuiContext = createContext();
MuiContext.displayName = "MuiContext";

function muiReducer(state, action) {
  switch (action.type) {
    case "MINI_SIDENAV": {
      return { ...state, miniSidenav: action.value };
    }
    case "TRANSPARENT_SIDENAV": {
      return { ...state, transparentSidenav: action.value };
    }
    case "WHITE_SIDENAV": {
      return { ...state, whiteSidenav: action.value };
    }
    case "SIDENAV_COLOR": {
      return { ...state, sidenavColor: action.value };
    }
    case "TRANSPARENT_NAVBAR": {
      return { ...state, transparentNavbar: action.value };
    }
    case "FIXED_NAVBAR": {
      return { ...state, fixedNavbar: action.value };
    }
    case "OPEN_CONFIGURATOR": {
      return { ...state, openConfigurator: action.value };
    }
    case "DIRECTION": {
      return { ...state, direction: action.value };
    }
    case "LAYOUT": {
      return { ...state, layout: action.value };
    }
    case "DARKMODE": {
      return { ...state, darkMode: action.value };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function MuiContextProvider({ children }) {
  const initialState = {
    miniSidenav: false,
    transparentSidenav: true,
    whiteSidenav: false,
    sidenavColor: "info",
    transparentNavbar: false,
    fixedNavbar: true,
    scrollPaddingTop: "100px",
    openConfigurator: false,
    direction: "ltr",
    layout: "dashboard",
    darkMode: false,
  };

  const [state, dispatch] = useReducer(muiReducer, initialState);

  useEffect(() => {
    if (state.fixedNavbar) {
      theme.components.MuiCssBaseline.styleOverrides.html.scrollPaddingTop =
        state.scrollPaddingTop;
      themeDark.components.MuiCssBaseline.styleOverrides.html.scrollPaddingTop =
        state.scrollPaddingTop;
    } else {
      delete theme.components.MuiCssBaseline.styleOverrides.html
        .scrollPaddingTop;
      delete themeDark.components.MuiCssBaseline.styleOverrides.html
        .scrollPaddingTop;
    }
  }, [state.fixedNavbar, state.scrollPaddingTop]);

  return (
    <MuiContext.Provider value={{ ...state, dispatch }}>
      {children}
    </MuiContext.Provider>
  );
}

function useMuiContext() {
  const context = useContext(MuiContext);

  if (!context) {
    throw new Error("MuiContext should be used inside the MuiContextProvider.");
  }

  return context;
}

// Typechecking props for the MuiContextProvider
MuiContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Context module functions
const setMiniSidenav = (dispatch, value) =>
  dispatch({ type: "MINI_SIDENAV", value });
const setTransparentSidenav = (dispatch, value) =>
  dispatch({ type: "TRANSPARENT_SIDENAV", value });
const setWhiteSidenav = (dispatch, value) =>
  dispatch({ type: "WHITE_SIDENAV", value });
const setSidenavColor = (dispatch, value) =>
  dispatch({ type: "SIDENAV_COLOR", value });
const setTransparentNavbar = (dispatch, value) =>
  dispatch({ type: "TRANSPARENT_NAVBAR", value });
const setFixedNavbar = (dispatch, value) =>
  dispatch({ type: "FIXED_NAVBAR", value });
const setOpenConfigurator = (dispatch, value) =>
  dispatch({ type: "OPEN_CONFIGURATOR", value });
const setDirection = (dispatch, value) =>
  dispatch({ type: "DIRECTION", value });
const setLayout = (dispatch, value) => dispatch({ type: "LAYOUT", value });
const setDarkMode = (dispatch, value) => dispatch({ type: "DARKMODE", value });

export {
  MuiContextProvider,
  useMuiContext,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
  setSidenavColor,
  setTransparentNavbar,
  setFixedNavbar,
  setOpenConfigurator,
  setDirection,
  setLayout,
  setDarkMode,
};
