import PropTypes from "prop-types";

import GenericTemplate from "components/templates/GenericTemplate";

import CoverDesign from "../../designs/CoverDesign";
import HeaderFragment from "../../fragments/HeaderFragment";
import ContentFragment from "../../fragments/ContentFragment";
import FooterFragment from "../../fragments/FooterFragment";

function NavCoverLayout({
  coverHeight,
  image,
  mainTitle,
  subTitle,
  logo,
  action,
  routes,
  children,
}) {
  return (
    <GenericTemplate>
      <HeaderFragment logo={logo} action={action} routes={routes} />
      <CoverDesign
        coverHeight={coverHeight}
        image={image}
        mainTitle={mainTitle}
        subTitle={subTitle}
      />
      <ContentFragment>{children}</ContentFragment>
      <FooterFragment color={"secondary"} />
    </GenericTemplate>
  );
}

NavCoverLayout.defaultProps = {
  coverHeight: "35vh",
};

// Typechecking props for the NavCoverLayout
NavCoverLayout.propTypes = {
  coverHeight: PropTypes.string,
  mainTitle: PropTypes.string,
  subTitle: PropTypes.string,
  image: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default NavCoverLayout;
