import form from "pages/participants/new/schemas/form";

const {
  formField: { userName, mobile, participantGroup, dob },
} = form;

const initialValues = {
  [userName.name]: "",
  [mobile.name]: "",
  [participantGroup.name]: [],
  [dob.name]: [],
};

export { initialValues as default };
