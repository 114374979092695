import PropTypes from "prop-types";

import { useState, useEffect, useCallback } from "react";

import MDSnackbar from "components/atoms/MDSnackbar";

function SuccessSnackbar({ title, dateTime, message, autoDismiss, onDismiss }) {
  const [show, setShow] = useState(true);
  const [isUnmounted, setIsUnmounted] = useState(false);

  const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

  const dismissAfter = useCallback(async () => {
    await sleep(5000);
    onDismiss && onDismiss();
    setShow(false);
  }, [onDismiss]);

  const dismiss = useCallback(() => {
    onDismiss && onDismiss();
    setShow(false);
  }, [onDismiss]);

  useEffect(() => {
    if (!isUnmounted) {
      if (autoDismiss) {
        dismissAfter();
      }
    }
    return () => {
      setIsUnmounted(true);
    };
  }, [autoDismiss, dismiss, dismissAfter, isUnmounted]);

  return (
    <MDSnackbar
      color="success"
      icon="notifications"
      title={title}
      dateTime={dateTime}
      content={message}
      open={show}
      close={() => dismiss()}
    />
  );
}

SuccessSnackbar.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  autoDismiss: PropTypes.bool,
};

export default SuccessSnackbar;
