import * as Yup from "yup";
import form, { rolesForm } from "pages/settings/users/manage/schemas/form";

const {
  formField: { displayName, designation, email, roles },
} = form;

const noValidation = Yup.object().shape({});

const validations = Yup.object().shape({
  [displayName.name]: Yup.string()
    .required(displayName.errorMsg)
    .max(200, displayName.invalidMsg),
  [designation.name]: Yup.string()
    .required(designation.errorMsg)
    .max(200, designation.invalidMsg),
  [email.name]: Yup.string()
    .required(email.errorMsg)
    .max(200, email.invalidMsg)
    .email(email.invalidMsg),
  [roles.name]: Yup.array()
    // .min(1, roles.min1Msg)
    .max(20, roles.max20Msg),
});

const {
  formField: { roleName },
} = rolesForm;

const rolesValidation = Yup.object().shape({
  [roleName.name]: Yup.string()
    .nullable()
    .required(roleName.errorMsg)
    .max(200, roleName.invalidMsg),
});

export { validations as default, noValidation, rolesValidation };
