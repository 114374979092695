import { createContext, useContext, useReducer } from "react";

import PropTypes from "prop-types";

const AppContext = createContext();
AppContext.displayName = "AppContext";

function appReducer(state, action) {
  switch (action.type) {
    case "MYTESTS_START": {
      return {
        ...state,
        mytestsStart: { ...state.mytestsStart, ...action.payload },
      };
    }
    case "INVITATIONS_REVIEW": {
      return {
        ...state,
        invitationsReview: { ...state.invitationsReview, ...action.payload },
      };
    }
    case "STUDENTS_REVIEW": {
      return {
        ...state,
        studentsReview: { ...state.studentsReview, ...action.payload },
      };
    }
    case "PRODUCTS_REVIEW": {
      return {
        ...state,
        productsReview: {
          ...state.productsReview,
          ...action.payload,
        },
      };
    }
    case "DIAGNOSTICTESTS_REVIEW": {
      return {
        ...state,
        diagnostictestsReview: {
          ...state.diagnostictestsReview,
          ...action.payload,
        },
      };
    }
    case "QUESTIONS_REVIEW": {
      return {
        ...state,
        questionsReview: { ...state.questionsReview, ...action.payload },
      };
    }
    case "SET_BABY_PROFILE": {
      return {
        ...state,
        selectedBabyProfile: {
          ...state.selectedBabyProfile,
          ...action.payload,
        },
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function AppContextProvider({ children }) {
  const [state, dispatch] = useReducer(appReducer, {
    mytestsStart: {
      pageIndex: 0,
    },
    invitationsReview: {
      expanded: ["root"],
      nodeId: "root",
      invitationId: null,
    },
    studentsReview: {
      expanded: ["root"],
      nodeId: "root",
      studentId: null,
    },
    productsReview: {
      expanded: ["root"],
      nodeId: null,
      productId: null,
    },
    diagnostictestsReview: {
      expanded: ["root"],
      nodeId: null,
      diagnostictestId: null,
    },
    questionsReview: {
      expanded: ["root"],
      nodeId: null,
      questionId: null,
      difficulties: ["D1", "D2", "D3", "D4"],
    },
    selectedBabyProfile: {
      babyUid: null,
      babyProfile: null,
    },
  });

  return (
    <AppContext.Provider value={{ ...state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
}

function useAppContext() {
  const context = useContext(AppContext);

  if (!context) {
    throw new Error("AppContext should be used inside the AppContextProvider.");
  }

  return context;
}

// Typechecking props for the AppContextProvider
AppContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Context module functions
const setMytestsStartState = (dispatch, payload) =>
  dispatch({ type: "MYTESTS_START", payload });
const setInvitationsReviewState = (dispatch, payload) =>
  dispatch({ type: "INVITATIONS_REVIEW", payload });
const setStudentsReviewState = (dispatch, payload) =>
  dispatch({ type: "STUDENTS_REVIEW", payload });
const setProductsReviewState = (dispatch, payload) =>
  dispatch({ type: "PRODUCTS_REVIEW", payload });
const setDiagnostictestsReviewState = (dispatch, payload) =>
  dispatch({ type: "DIAGNOSTICTESTS_REVIEW", payload });
const setQuestionsReviewState = (dispatch, payload) =>
  dispatch({ type: "QUESTIONS_REVIEW", payload });
const setSelectedBabyProfile = (dispatch, payload) =>
  dispatch({ type: "SET_BABY_PROFILE", payload });

export {
  AppContextProvider,
  useAppContext,
  setMytestsStartState,
  setInvitationsReviewState,
  setStudentsReviewState,
  setProductsReviewState,
  setDiagnostictestsReviewState,
  setQuestionsReviewState,
  setSelectedBabyProfile,
};
