import { useEffect, useState, useCallback } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import ErrorBoundary from "components/molecules/ErrorBoundary";

import Configurator from "components/molecules/Configurator";
import Sidenav from "components/organisms/Sidenav";
import Modal from "@mui/material/Modal";
import Dialog from "@mui/material/Dialog";

import theme from "assets/theme";
import themeDark from "assets/theme-dark";
import brandWhite from "assets/images/logo.png";
import brandDark from "assets/images/logo.png";
import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";
import MDButton from "components/atoms/MDButton";

import ErrorAlert from "components/molecules/Notifications/ErrorAlert";
import SuccessAlert from "components/molecules/Notifications/SuccessAlert";

import { useMuiContext, setMiniSidenav } from "context/MuiContext";

import { useAuthContext } from "context/AuthContext";
import RequireAuth from "components/molecules/RequireAuth";

import { AbacProvider } from "react-abac";

import navigate from "models/navigate";

import { useCollectionSnapshot } from "hooks/useCollectionSnapshot";
import { useDocumentSnapshot } from "hooks/useDocumentSnapshot";
import { useDocument } from "hooks/useDocument";

import { Formik, Form } from "formik";
import * as Yup from "yup";

import FormField from "components/molecules/FormField";
import {
  updateUserPassword,
  reauthenticateUserWithCredential,
  subscribeAuthState,
  signOutUser,
} from "services/authentication";

import { useLogManager } from "hooks/useLogManager";

import moment from "moment-timezone";

import zxcvbn from "zxcvbn";

import crypto from "crypto-browserify";

import bgMaintenance from "assets/images/bg-maintenance.jpg";

import Countdown from "react-countdown";

const abacRulesQueries = {
  whereQueries: [
    {
      field: "deletedAt",
      condition: "==",
      value: null,
    },
  ],
};

export default function App() {
  const [abacRules, setAbacRules] = useState([]);
  const [isFirstTimeSignIn, setIsFirstTimeSignIn] = useState(false);
  const [isPasswordExpired, setIsPasswordExpired] = useState(false);
  // const [isAutomaticSignOut, setIsAutomaticSignOut] = useState(false);
  const [isContinue, setIsContinue] = useState(false);

  // const [sessionTimeout, setSessionTimeout] = useState(null);
  const sessionDuration = 1000 * 60 * 30; // 30 minutes

  const { logUserActivity } = useLogManager();

  const { collectionData: abacRulesData } = useCollectionSnapshot(
    "roles",
    abacRulesQueries
  );
  const { documentData: maintenanceData } = useDocumentSnapshot(
    "configs",
    "maintenance"
  );
  const { retrieveDoc, retrieveDocs, purgeDoc, updateDoc, serverTimestamp } =
    useDocument();

  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [openAutomaticSignOut, setOpenAutomaticSignOut] = useState(false);

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const {
    miniSidenav,
    layout,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
    dispatch,
  } = useMuiContext();

  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  const {
    user,
    roles,
    displayName,
    displayNameKey,
    sessionId,
    // authIsReady,
    dispatch: dispatchAuth,
  } = useAuthContext();

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  const loadAbacRules = useCallback(async () => {
    const rulesRetrievedOnce = await retrieveDocs("roles", abacRulesQueries);
    const data = abacRulesData.length > 0 ? abacRulesData : rulesRetrievedOnce;
    const ruleEntries = new Map(
      data.map((rule) => {
        const entries = new Map(
          rule.data.permissions.map((perm) => [perm, true])
        );
        const permissionsObj = Object.fromEntries(entries);
        return [rule.data.roleName, permissionsObj];
      })
    );
    const rules = Object.fromEntries(ruleEntries);
    setAbacRules(rules);
  }, [abacRulesData, retrieveDocs]);

  const verifyFirtTimeSignIn = useCallback(async () => {
    try {
      const retrievedUser = user.uid && (await retrieveDoc("users", user?.uid));

      const required = retrievedUser?.data?.changePasswordRequired;

      setIsFirstTimeSignIn(required);
    } catch (err) {
      console.error(err);
    }
  }, [user?.uid, retrieveDoc]);

  const verifyPasswordExpired = useCallback(async () => {
    try {
      const retrievedUser = user.uid && (await retrieveDoc("users", user?.uid));

      const latestPasswordUpdateDate = retrievedUser?.data?.hashUpdatedAt;

      if (latestPasswordUpdateDate) {
        const lastMoment = moment.tz(
          latestPasswordUpdateDate.toDate(),
          "Asia/Singapore"
        );
        const currentMoment = moment().tz("Asia/Singapore");

        const required = currentMoment.diff(lastMoment, "days") > 365;
        setError(null);

        setIsPasswordExpired(required);
      }
    } catch (err) {
      console.error(err);
    }
  }, [user?.uid, retrieveDoc]);

  const setOpenClosePasswordChange = useCallback(async () => {
    try {
      if (isFirstTimeSignIn || isPasswordExpired) {
        setOpenChangePassword(true);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isFirstTimeSignIn, isPasswordExpired]);

  const signOutSession = useCallback(async () => {
    try {
      if (user?.uid) {
        setOpenAutomaticSignOut(false);
        await purgeDoc(`metadata/${user.uid}/session`, sessionId);
        await logUserActivity({
          uid: user.uid,
          activity: "signout",
          document: null,
          timestamp: serverTimestamp(),
        });
        signOutUser();
        dispatchAuth({ type: "SIGNOUT" });
      }
    } catch (err) {
      console.error(err);
    }
  }, [
    dispatchAuth,
    logUserActivity,
    purgeDoc,
    serverTimestamp,
    sessionId,
    user?.uid,
  ]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    let sessionTimeout = null;
    let flagSignOutInterval = null;
    let unsubscribe = null;
    loadAbacRules();
    if (user) {
      verifyFirtTimeSignIn();
      verifyPasswordExpired();
      setOpenClosePasswordChange();

      unsubscribe = subscribeAuthState(async (user) => {
        try {
          // setSessionTimeout(null);

          if (user) {
            // User is logged in.
            // set interval to repeatedly check if ID token valid and not revoked

            flagSignOutInterval = setInterval(async () => {
              try {
                if (sessionId) {
                  const retrievedMetadata = await retrieveDoc(
                    `metadata/${user.uid}/session`,
                    sessionId
                  );

                  if (retrievedMetadata?.data?.flagSignOut) {
                    signOutSession();
                  }
                }
              } catch (error) {
                console.error(error);
              }
            }, 60000);

            // Fetch the decoded ID token and create a session timeout which signs the user out.
            const millisecondsUntilExpiration = await user
              .getIdTokenResult()
              .then((idTokenResult) => {
                // Times are in milliseconds
                const authTime = idTokenResult.claims.auth_time * 1000;
                const millisecondsUntilExpiration =
                  sessionDuration - (Date.now() - authTime);
                return millisecondsUntilExpiration;
              })
              .catch(async (error) => {
                if (error.includes("auth/user-token-expired")) {
                  signOutSession();
                }
                console.error("Error get ID Toke Result:", error);
              });

            // setSessionTimeout(
            sessionTimeout = setTimeout(() => {
              // setIsAutomaticSignOut(true);

              if (user?.uid) {
                setOpenAutomaticSignOut(true);
              }
            }, millisecondsUntilExpiration);
            // );
          } else {
            // User is logged out.
            sessionTimeout && clearTimeout(sessionTimeout);
            sessionTimeout = null;
            flagSignOutInterval && clearInterval(flagSignOutInterval);
            flagSignOutInterval = null;
          }
        } catch (error) {
          console.error(error);
        }
      });
    } else {
      // User is logged out.
      sessionTimeout && clearTimeout(sessionTimeout);
      sessionTimeout = null;
      flagSignOutInterval && clearInterval(flagSignOutInterval);
      flagSignOutInterval = null;
    }

    return () => {
      if (!user) {
        sessionTimeout && clearTimeout(sessionTimeout);
        sessionTimeout = null;
        flagSignOutInterval && clearInterval(flagSignOutInterval);
        flagSignOutInterval = null;
      }
      unsubscribe && unsubscribe();
    };
  }, [
    dispatchAuth,
    error,
    loadAbacRules,
    logUserActivity,
    purgeDoc,
    retrieveDoc,
    retrieveDocs,
    serverTimestamp,
    sessionDuration,
    sessionId,
    setOpenClosePasswordChange,
    signOutSession,
    updateDoc,
    user,
    verifyFirtTimeSignIn,
    verifyPasswordExpired,
  ]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const getRoutes = (allRoutes) =>
    allRoutes
      .flatMap((route) => {
        if (route.collapse) {
          return getRoutes(route.collapse);
        }

        if (route.route) {
          return (
            <Route
              key={route.key}
              path={route.route}
              element={
                route.auth ? (
                  <RequireAuth>{route.component}</RequireAuth>
                ) : (
                  route.component
                )
              }
            />
          );
        }

        return null;
      })
      .filter((route) => {
        return route != null;
      });

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <></>;
    } else {
      return (
        <MDBox>
          {minutes.toString().padStart(2, "0")}:
          {seconds.toString().padStart(2, "0")}
        </MDBox>
      );
    }
  };

  return (
    <>
      {/* {authIsReady && ( */}
      <ErrorBoundary>
        <ThemeProvider theme={darkMode ? themeDark : theme}>
          <CssBaseline />
          {/* {abacRules?.length > 0 ? ( */}
          <AbacProvider
            rules={abacRules}
            user={user}
            roles={roles}
            permissions={[]}
          >
            {maintenanceData?.status && (
              <Dialog fullScreen open={true}>
                <MDBox height="100%">
                  <MDBox
                    sx={{
                      backgroundImage: ({
                        functions: { linearGradient, rgba },
                        palette: { gradients },
                      }) =>
                        bgMaintenance &&
                        `${linearGradient(
                          rgba(gradients.dark.main, 0),
                          rgba(gradients.dark.state, 0)
                        )}, url(${bgMaintenance})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      height: "100%",
                    }}
                  >
                    <MDBox
                      pt={5}
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <MDTypography variant="h1" color="black">
                        Cradle 2 is currently down for maintenance.
                      </MDTypography>
                      <MDTypography variant="h4" color="black">
                        We expect to be back in a couple hours. Thanks for your
                        patience.
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </Dialog>
            )}
            {(isFirstTimeSignIn || isPasswordExpired) && (
              <Modal
                open={openChangePassword}
                // onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDBox sx={style}>
                  <MDBox
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                    }}
                  >
                    {error && <ErrorAlert message={error} />}
                    {success && <SuccessAlert message={success} />}
                  </MDBox>
                  <MDBox mb={3}>
                    <MDTypography mb={1} variant="h4">
                      Change Password
                    </MDTypography>
                    {isFirstTimeSignIn && (
                      <MDTypography variant="body2" sx={{ lineHeight: 1.25 }}>
                        Welcome to Cradle 2, you are required to set a new
                        password upon first time signed in.
                      </MDTypography>
                    )}
                    {isPasswordExpired && (
                      <MDTypography variant="body2" sx={{ lineHeight: 1.25 }}>
                        Passwords need to be changed once every TWELVE(12)
                        months.
                      </MDTypography>
                    )}
                  </MDBox>
                  <Formik
                    initialValues={{
                      currentPassword: "",
                      newPassword: "",
                      confirmNewPassword: "",
                    }}
                    validationSchema={Yup.object({
                      currentPassword: Yup.string().required("Required"),
                      newPassword: Yup.string()
                        .required("Required")
                        .test(
                          "isAccountId",
                          "Must not be the same as user ID",
                          async (value, ctx) => {
                            if (value?.length > 0) {
                              return (
                                value.toLowerCase() !==
                                user?.email?.toLowerCase()
                              );
                            }
                            return true;
                          }
                        )
                        .notOneOf(
                          [user?.email],
                          "Must not be the same as user ID"
                        )
                        .test("isStrongPassword", "sss", async (value, ctx) => {
                          if (value?.length > 0) {
                            const cvbn = zxcvbn(value);
                            if (cvbn.score !== 3 && cvbn.score !== 4) {
                              return ctx.createError({
                                message: cvbn.feedback?.suggestions
                                  ?.map(
                                    (suggestion, idx) =>
                                      "(" + (idx + 1) + ") " + suggestion
                                  )
                                  .join("\n"),
                              });
                            }
                          }
                          return true;
                        })
                        .matches(
                          "^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#&()-\\[{}\\]:;',?/*~$^+=<>]).{15,100}$",
                          "Must have:\n At least 15 characters\n At least one digit\n At least one lowercase character\n At least one uppercase character\n At least one special character"
                        ),
                      confirmNewPassword: Yup.string()
                        .required("Required")
                        .oneOf(
                          [Yup.ref("newPassword"), null],
                          "Passwords must match"
                        ),
                    })}
                    onSubmit={async (
                      values,
                      { setSubmitting, setFieldValue }
                    ) => {
                      try {
                        setSubmitting(true);

                        // check if reuse previous 5 password
                        const retrievedUser = await retrieveDoc(
                          "users",
                          user.uid
                        );

                        const salt = retrievedUser.data.salt;
                        crypto.pbkdf2(
                          values.newPassword,
                          salt,
                          4300000,
                          64,
                          "sha512",
                          async (err, derivedKey) => {
                            if (err) throw err;

                            const hash = derivedKey.toString("hex");

                            if (!retrievedUser.data?.hashes?.includes(hash)) {
                              const hashes = retrievedUser.data.hashes
                                .slice(-4)
                                .concat([hash]);

                              // update password
                              await reauthenticateUserWithCredential(
                                user.email,
                                values.currentPassword
                              );

                              await updateUserPassword(values.newPassword);

                              await updateDoc("users", user.uid, {
                                hashes: hashes,
                                hashUpdatedAt: serverTimestamp(),
                                modifiedAt: serverTimestamp(),
                                modifiedBy: user.uid,
                                changePasswordRequired: false,
                              });

                              setSuccess("Password updated.");
                              await new Promise(() => {
                                setTimeout(() => {
                                  setSuccess(null);
                                  setOpenChangePassword(false);
                                }, 3000);
                              });
                            } else {
                              setFieldValue("newPassword", "");
                              setFieldValue("confirmNewPassword", "");
                              setError(
                                "Cannot reuse last 5 passwords. Please try again with new passord."
                              );
                              await new Promise(() => {
                                setTimeout(() => {
                                  setError(null);
                                }, 5000);
                              });
                            }
                          }
                        );
                      } catch (error) {
                        console.error(error);
                        setError(
                          "Password update failed. Please try again or contact administrator"
                        );
                      } finally {
                        setSubmitting(false);
                      }
                    }}
                  >
                    {({ values, errors, touched, isSubmitting }) => (
                      <Form>
                        <FormField
                          type="password"
                          label="Current Password"
                          name="currentPassword"
                          error={
                            errors.currentPassword && touched.currentPassword
                          }
                          success={
                            values.currentPassword.length > 0 &&
                            !errors.currentPassword
                          }
                        />
                        <FormField
                          type="password"
                          label="New Password"
                          name="newPassword"
                          error={errors.newPassword && touched.newPassword}
                          success={
                            values.newPassword.length > 0 && !errors.newPassword
                          }
                        />
                        <FormField
                          type="password"
                          label="Confirm New Password"
                          name="confirmNewPassword"
                          error={
                            errors.confirmNewPassword &&
                            touched.confirmNewPassword
                          }
                          success={
                            values.confirmNewPassword.length > 0 &&
                            !errors.confirmNewPassword
                          }
                        />

                        <MDBox mt={2} mb={1}>
                          <MDButton
                            disabled={isSubmitting}
                            type="submit"
                            variant="gradient"
                            color="success"
                            fullWidth
                          >
                            Submit
                          </MDButton>
                        </MDBox>
                      </Form>
                    )}
                  </Formik>
                </MDBox>
              </Modal>
            )}
            {/* {isAutomaticSignOut && ( */}
            <Modal
              open={openAutomaticSignOut}
              // onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <MDBox sx={style}>
                <MDBox
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                  }}
                >
                  {error && <ErrorAlert message={error} />}
                  {success && <SuccessAlert message={success} />}
                </MDBox>
                <MDBox mb={3} textAlign="center">
                  <MDTypography mb={1} variant="h4">
                    Automatic Sign Out in
                  </MDTypography>

                  <MDBox mb={1} textAlign="center">
                    <MDTypography variant="h2" sx={{ lineHeight: 1.25 }}>
                      <Countdown
                        date={Date.now() + 60 * 1000}
                        renderer={renderer}
                        onComplete={() => {
                          if (!isContinue) {
                            signOutSession();
                          }
                        }}
                      />
                    </MDTypography>
                  </MDBox>

                  <MDTypography variant="body" sx={{ lineHeight: 1.25 }}>
                    Would you like to continue using Cradle 2?
                  </MDTypography>
                </MDBox>
                <MDBox mt={2} mb={1}>
                  <MDButton
                    variant="gradient"
                    color="success"
                    fullWidth
                    onClick={() => {
                      setIsContinue(true);
                      setOpenAutomaticSignOut(false);

                      const millisecondsUntilExpiration = sessionDuration;

                      setTimeout(() => {
                        // setIsAutomaticSignOut(true);
                        setIsContinue(false);
                        setOpenAutomaticSignOut(true);
                      }, millisecondsUntilExpiration);
                    }}
                  >
                    Continue
                  </MDButton>
                </MDBox>
                <MDBox mt={2} mb={1}>
                  <MDButton
                    variant="gradient"
                    color="error"
                    fullWidth
                    onClick={signOutSession}
                  >
                    Sign Out Now
                  </MDButton>
                </MDBox>
              </MDBox>
            </Modal>
            {/* )} */}
            {user && user.emailVerified && layout === "dashboard" && (
              <>
                <Sidenav
                  color={sidenavColor}
                  brand={
                    (transparentSidenav && !darkMode) || whiteSidenav
                      ? brandDark
                      : brandWhite
                  }
                  brandName="CRADLE 2"
                  links={navigate(displayName, displayNameKey)}
                  onMouseEnter={handleOnMouseEnter}
                  onMouseLeave={handleOnMouseLeave}
                />
                <Configurator />
              </>
            )}
            {user && user.emailVerified && layout === "vr" && <Configurator />}

            <Routes>{getRoutes(navigate(displayName, displayNameKey))}</Routes>
          </AbacProvider>
          {/* ) : (
              <SubmissionProgress />
            )} */}
        </ThemeProvider>
      </ErrorBoundary>
      {/* )} */}
    </>
  );
}
