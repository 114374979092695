import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import MDTypography from "components/atoms/MDTypography";

function SubmissionProgress() {
  return (
    <Backdrop
      sx={{
        color: "#fff",
        backdropFilter: "blur(5px)",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={true}
    >
      <CircularProgress />
      <MDTypography mx={2} variant="body1" color="white">
        Loading ...
      </MDTypography>
    </Backdrop>
  );
}

export default SubmissionProgress;
