const form = {
  formId: "role-form",
  formField: {
    roleName: {
      name: "roleName",
      label: "Role",
      type: "text",
      errorMsg: "Role is required.",
      invalidMsg: "Role is invalid.",
    },
    permissions: {
      name: "permissions",
      label: "Permissions",
      type: "box",
      min1Msg: "Must have at least 1 Permission.",
      max200Msg: "Must have at most 200 Permissions.",
    },
  },
};

const permissionsForm = {
  formId: "permissions-form",
  formField: {
    permissionName: {
      name: "permissionName",
      label: "Permission",
      type: "text",
      errorMsg: "Permission is required.",
      invalidMsg: "Permission is invalid.",
    },
  },
};

export { form as default, permissionsForm };
