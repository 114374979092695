import { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";

import { useMuiContext } from "context/MuiContext";

function Sidenav() {
  const [sideNavPosition, setSideNavPosition] = useState();
  const { fixedNavbar, scrollPaddingTop, darkMode } = useMuiContext();

  useEffect(() => {
    if (fixedNavbar) {
      setSideNavPosition(scrollPaddingTop);
    } else {
      setSideNavPosition("1%");
    }
  }, [fixedNavbar, scrollPaddingTop]);

  const sidenavItems = [
    { icon: "person", label: "Profile", href: "profile" },
    {
      icon: "receipt_long",
      label: "Basic Information",
      href: "basic-information",
    },
  ];

  const renderSidenavItems = sidenavItems.map(({ icon, label, href }, key) => {
    const itemKey = `item-${key}`;

    return (
      <MDBox key={itemKey} component="li" pt={key === 0 ? 0 : 1}>
        <MDTypography
          component="a"
          href={`#${href}`}
          variant="button"
          fontWeight="regular"
          sx={({
            borders: { borderRadius },
            functions: { pxToRem },
            palette: { light },
            transitions,
          }) => ({
            display: "flex",
            alignItems: "center",
            borderRadius: borderRadius.md,
            padding: `${pxToRem(10)} ${pxToRem(16)}`,
            transition: transitions.create("background-color", {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.shorter,
            }),

            "&:hover": {
              backgroundColor: light.main,
            },
          })}
        >
          <MDBox mr={1.5} lineHeight={1} color={darkMode ? "white" : "dark"}>
            <Icon fontSize="small">{icon}</Icon>
          </MDBox>
          {label}
        </MDTypography>
      </MDBox>
    );
  });

  return (
    <Card
      sx={{
        borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
        position: "sticky",
        top: sideNavPosition,
      }}
    >
      <MDBox
        component="ul"
        display="flex"
        flexDirection="column"
        p={2}
        m={0}
        sx={{ listStyle: "none" }}
      >
        {renderSidenavItems}
      </MDBox>
    </Card>
  );
}

export default Sidenav;
