const form = {
  formId: "account-form",
  formField: {
    firstName: {
      name: "firstName",
      label: "First Name",
      type: "text",
      errorMsg: "First Name is required.",
      invalidMsg: "First Name is invalid.",
    },
    lastName: {
      name: "lastName",
      label: "Last Name",
      type: "text",
      errorMsg: "Last Name is required.",
      invalidMsg: "Last Name is invalid.",
    },
    displayName: {
      name: "displayName",
      label: "Display Name",
      type: "text",
      errorMsg: "Display Name is required.",
      invalidMsg: "Display Name is invalid.",
    },
    designation: {
      name: "designation",
      label: "Designation",
      type: "text",
      errorMsg: "Designation is required.",
      invalidMsg: "Designation is invalid.",
    },
    email: {
      name: "email",
      label: "Email",
      type: "text",
      errorMsg: "Email is required.",
      invalidMsg: "Email is invalid.",
    },
    roles: {
      name: "roles",
      label: "Roles",
      type: "box",
      min1Msg: "Must have at least 1 Role.",
      max50Msg: "Must have at most 50 Roles.",
    },
  },
};

const rolesForm = {
  formId: "roles-form",
  formField: {
    roleName: {
      name: "roleName",
      label: "Role",
      type: "text",
      errorMsg: "Role is required.",
      invalidMsg: "Role is invalid.",
    },
  },
};

export { form as default, rolesForm };
