import { useEffect, useState } from "react";
import { sendUserEmailVerification } from "services/authentication";

export const useVerifyEmail = () => {
  const [isUnmounted, setIsUnmounted] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const message =
    "A link to activate your account has been emailed to the address provided. Please click the link in the email to complete the sign up process.";

  const sendEmailVerification = async (continueURL) => {
    try {
      setError(null);

      await sendUserEmailVerification(continueURL);

      if (!isUnmounted) {
        setError(null);
        setSuccess(message);
      }
    } catch (err) {
      if (!isUnmounted) {
        // SEE https://cheatsheetseries.owasp.org/cheatsheets/Authentication_Cheat_Sheet.html#authentication-and-error-messages
        // Don't show error on console
        // Respond with generic error message regardless
        // console.error(err.message);
        setError(null);
        setSuccess(message);
      }
    }
  };

  useEffect(() => {
    return () => setIsUnmounted(true);
  }, []);

  return { sendEmailVerification, error, success };
};
