import { firestore } from "configs/firebase";
import {
  addDoc,
  setDoc,
  doc,
  getDoc,
  collection,
  query,
  where,
  orderBy,
  limit,
  startAt,
  startAfter,
  endAt,
  endBefore,
  getDocs,
  updateDoc,
  deleteDoc,
  onSnapshot,
  serverTimestamp,
  Timestamp,
} from "firebase/firestore";

const createDocument = async (collectionPath, document, documentId) => {
  if (documentId) {
    await setDoc(doc(firestore, collectionPath, documentId), document);
    return retrieveDocument(collectionPath, documentId);
  } else {
    const docRef = await addDoc(
      collection(firestore, collectionPath),
      document
    );
    return retrieveDocument(collectionPath, docRef.id);
  }
};

const createDocumentOnPath = async (documentPath, document) => {
  await setDoc(doc(firestore, documentPath), document);
  return retrieveDocumentOnPath(documentPath);
};

const retrieveDocument = (collectionPath, documentId) => {
  return getDoc(doc(collection(firestore, collectionPath), documentId));
};

const retrieveDocumentOnPath = (documentPath) => {
  return getDoc(doc(firestore, documentPath));
};

const retrieveDocuments = (collectionPath, collectionQuery) => {
  const firestoreQuery = constructQuery(collectionPath, collectionQuery);
  return getDocs(firestoreQuery);
};

const updateDocument = async (collectionPath, documentId, document) => {
  await updateDoc(
    doc(collection(firestore, collectionPath), documentId),
    document,
    { merge: true }
  );
  return retrieveDocument(collectionPath, documentId);
};

const updateDocumentOnPath = async (documentPath, document) => {
  await updateDoc(doc(firestore, documentPath), document, { merge: true });
  return retrieveDocumentOnPath(documentPath);
};

const deleteDocument = async (collectionPath, documentId, document) => {
  await updateDoc(
    doc(collection(firestore, collectionPath), documentId),
    document,
    { merge: true }
  );
  return retrieveDocument(collectionPath, documentId);
};

const purgeDocument = (collectionPath, documentId) => {
  return deleteDoc(doc(collection(firestore, collectionPath), documentId));
};

const subscribeDocumentSnapshot = (
  collectionPath,
  documentId,
  onNext,
  onError,
  onCompletion
) => {
  return onSnapshot(
    doc(collection(firestore, collectionPath), documentId),
    onNext,
    onError,
    onCompletion
  );
};

const subscribeCollectionSnapshot = (
  collectionPath,
  collectionQuery,
  onNext,
  onError,
  onCompletion
) => {
  const firestoreQuery = constructQuery(collectionPath, collectionQuery);
  return onSnapshot(firestoreQuery, onNext, onError, onCompletion);
};

const constructQuery = (collectionPath, collectionQuery = {}) => {
  const collectionRef = collection(firestore, collectionPath);
  const { whereQueries, orderByQueries, limitQuery, cursorQueries } =
    collectionQuery;
  const queryConstraints = [];

  if (whereQueries && whereQueries.length > 0) {
    for (const query of whereQueries) {
      queryConstraints.push(where(query.field, query.condition, query.value));
    }
  }

  if (orderByQueries && orderByQueries.length > 0) {
    for (const query of orderByQueries) {
      queryConstraints.push(orderBy(query.field, query.direction));
    }
  }

  if (limitQuery && limitQuery.number) {
    queryConstraints.push(limit(limitQuery.number));
  }

  if (cursorQueries && cursorQueries.length > 0) {
    for (const query of cursorQueries) {
      query.startAt && queryConstraints.push(startAt(query.startAt));
      query.startAfter && queryConstraints.push(startAfter(query.startAfter));
      query.endAt && queryConstraints.push(endAt(query.endAt));
      query.endBefore && queryConstraints.push(endBefore(query.endBefore));
    }
  }

  return query(collectionRef, ...queryConstraints);
};

const convertToTimestamp = (date) => {
  return Timestamp.fromDate(date);
};

export {
  createDocument,
  createDocumentOnPath,
  retrieveDocument,
  retrieveDocumentOnPath,
  retrieveDocuments,
  updateDocument,
  updateDocumentOnPath,
  deleteDocument,
  purgeDocument,
  subscribeCollectionSnapshot,
  subscribeDocumentSnapshot,
  serverTimestamp,
  convertToTimestamp,
};
