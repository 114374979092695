import form, {
  permissionsForm,
} from "pages/settings/roles/manage/schemas/form";

const {
  formField: { roleName, permissions },
} = form;

const initialValues = {
  [roleName.name]: "",
  [permissions.name]: [],
};

const {
  formField: { permissionName },
} = permissionsForm;

const permissionsInitialValues = {
  [permissionName.name]: "",
};

export { initialValues as default, permissionsInitialValues };
