// Don't handle exception in this React Hook to allow exception bubbling
// Handle exception at upwards level:
//   send error log back to server
//   show generic error message on snackbar to inform user to report to admin

import { useCallback } from "react";
import { useDocument } from "hooks/useDocument";

export const useLogManager = () => {
  const { createDoc } = useDocument();

  const logUserActivity = useCallback(
    async ({ uid, activity, document, timestamp }) => {
      // try {
      await createDoc(
        "activitylogs",
        {
          uid: uid,
          activity: activity,
          document: document,
          timestamp: timestamp,
        },
        uid
      );
      // } catch (error) {
      //   console.error(error);
      // }
      // const createLog = async () => {
      //   try {
      //     await createDoc(
      //       "activitylogs",
      //       {
      //         uid: uid,
      //         activity: activity,
      //         document: document,
      //         timestamp: timestamp,
      //       },
      //       uid
      //     );
      //   } catch (error) {
      //     console.error(error);
      //     await createLog();
      //   }
      // };

      // await createLog();
    },
    [createDoc]
  );

  return {
    logUserActivity,
  };
};
