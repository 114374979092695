import PropTypes from "prop-types";

import MDBox from "components/atoms/MDBox";
import DefaultFooter from "components/organisms/Footer/DefaultFooter";

function FooterFragment({ color, children }) {
  return (
    <>
      <MDBox component="footer" py={6}>
        <DefaultFooter color={color} />
      </MDBox>
      {children}
    </>
  );
}

// Typechecking props for the FooterFragment
FooterFragment.propTypes = {
  color: PropTypes.oneOf(["text", "secondary", "white", "dark"]),
  children: PropTypes.node,
};

export default FooterFragment;
