// Don't handle exception in this React Hook to allow exception bubbling
// Handle exception at upwards level:
//   send error log back to server
//   show generic error message on snackbar to inform user to report to admin

import { useEffect, useState } from "react";
import { subscribeDocumentSnapshot } from "services/firestore";

import _ from "lodash";

export const useDocumentSnapshot = (collectionPath, documentId) => {
  const [documentData, setDocumentData] = useState(null);

  useEffect(() => {
    const unsubscribe = subscribeDocumentSnapshot(
      collectionPath,
      documentId,
      (docSnap) => {
        const result = docSnap.data();
        setDocumentData(_.cloneDeep(result));
      }
    );

    // cleanup on unmount
    return () => {
      unsubscribe && unsubscribe();
    };
  }, [collectionPath, documentId]);

  return { documentData };
};
