import { useEffect, useState } from "react";
import { sendUserPasswordResetEmail } from "services/authentication";

import { useLogManager } from "hooks/useLogManager";
import { useDocument } from "hooks/useDocument";

export const useResetPassword = () => {
  const [isUnmounted, setIsUnmounted] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const { serverTimestamp } = useDocument();
  const { logUserActivity } = useLogManager();

  const message =
    "A link to reset your password has been emailed to the address provided. Please click the link in the email to complete the password reset process.";

  const sendPasswordResetEmail = async (email, continueURL) => {
    try {
      setError(null);

      await sendUserPasswordResetEmail(email, continueURL);

      await logUserActivity({
        uid: email,
        activity: "reset password",
        document: null,
        timestamp: serverTimestamp(),
      });

      if (!isUnmounted) {
        setError(null);
        setSuccess(message);
      }
    } catch (err) {
      if (!isUnmounted) {
        console.error(err.message);
        setError(null);
        setSuccess(message);
      }
    }
  };

  useEffect(() => {
    return () => setIsUnmounted(true);
  }, []);

  return { sendPasswordResetEmail, error, success };
};
