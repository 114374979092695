import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";

import MDBox from "components/atoms/MDBox";
import MDButton from "components/atoms/MDButton";
import MDTypography from "components/atoms/MDTypography";

import ErrorSnackbar from "components/molecules/Notifications/ErrorSnackbar";
import SuccessSnackbar from "components/molecules/Notifications/SuccessSnackbar";

import DashboardLayout from "components/templates/DashboardLayout";
import Topnav from "components/organisms/Topnav";
import DashboardFooter from "components/organisms/Footer/DashboardFooter";
import PermissionDenied from "components/organisms/PermissionDenied";
import InvalidOperation from "components/organisms/InvalidOperation";
import IncompleteOperation from "components/organisms/IncompleteOperation";

import SubmissionProgress from "components/organisms/SubmissionProgress";
import BasicInformation from "pages/participants/new/components/BasicInformation";
import ChildrenInformation from "pages/participants/new/components/ChildrenInformation";
import Sidenav from "pages/participants/new/components/Sidenav";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import form from "pages/participants/new/schemas/form";

import { AllowedTo } from "react-abac";

import { useNewParticipantManager } from "pages/participants/hooks/useNewParticipantManager";

function NewUser() {
  const {
    modeTitle,
    modeSubmit,
    modeFieldDisabled,
    submitNew,
    response,
    modePermission,
    dispatchDismiss,
    dispatchError,
    parentsData,
  } = useNewParticipantManager();

  const dispatchDismissAfter = (millisecond) => {
    return new Promise(() => {
      setTimeout(() => {
        dispatchDismiss();
      }, millisecond);
    });
  };

  const { formId, formField } = form;
  const continueURL = window.location.href.replace(
    window.location.pathname,
    ""
  );

  const [userNamesTaken, setUserNamesTaken] = useState([]);
  const [open, setOpen] = useState(false);
  const showStartDialog = () => {
    setOpen(true);
  };

  const closeStartDialog = () => {
    setOpen(false);
  };

  const handleSubmit = async (values, actions) => {
    try {
      const matchedRecord = parentsData.filter(
        (parentData) => parentData.data.userName === values.userName
      );
      matchedRecord.length > 0
        ? showStartDialog()
        : await submitNew(values, continueURL);
    } catch (err) {
      dispatchError(err);
      actions.setSubmitting(false);
    } finally {
      actions.setSubmitting(false);
      await dispatchDismissAfter(3000);
    }
  };

  useEffect(() => {
    try {
      if (parentsData.length > 0) {
        const userNamesTaken = parentsData.map((elm) => elm.data.userName);
        setUserNamesTaken(userNamesTaken);
      }
    } catch (err) {
      dispatchError(err);
    }
  }, [parentsData, dispatchError]);

  return (
    <DashboardLayout>
      <Topnav title={modeTitle} />
      <AllowedTo perform={modePermission} no={() => <PermissionDenied />}>
        {response.isPending ? (
          <SubmissionProgress />
        ) : (
          <>
            {response.error && (
              <>
                {response.error?.name === "OperationInvalidError" && (
                  <InvalidOperation />
                )}
                {response.error?.name === "OperationIncompleteError" && (
                  <IncompleteOperation />
                )}
                <ErrorSnackbar
                  title={modeTitle}
                  dateTime=""
                  message={response.error?.message}
                  autoDismiss
                />
              </>
            )}
            {response.success && (
              <SuccessSnackbar
                title={modeTitle}
                dateTime=""
                message={response.success}
                autoDismiss
              />
            )}
            <MDBox pt={3}>
              <MDBox>
                {response.error?.name !== "OperationInvalidError" && (
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={2}>
                      <Sidenav />
                    </Grid>

                    <Grid item xs={12} lg={10}>
                      <MDBox mb={3}>
                        <Formik
                          //enableReinitialize
                          initialValues={{
                            userName: "",
                            mobile: "",
                            whatsappInvite: "",
                            children: [
                              {
                                participantGroup: null,
                                dob: null,
                              },
                            ],
                          }}
                          validationSchema={Yup.object().shape({
                            userName: Yup.string()
                              .required("User Name is required.")
                              .notOneOf(
                                userNamesTaken,
                                "User Name has been taken"
                              ),
                            mobile: Yup.string()
                              .required("Mobile number is required.")
                              .min(10, "Please enter a valid mobile number"),
                            /*whatsappInvite: Yup.string().required(
                              "WhatsApp Invite is required."
                            ),*/
                            children: Yup.array().of(
                              Yup.object().shape({
                                participantGroup: Yup.string()
                                  .nullable()
                                  .required("Participant Group  is required"),
                                dob: Yup.string()
                                  .nullable()
                                  .required("DOB is required"),
                              })
                            ),
                          })}
                          onSubmit={handleSubmit}
                        >
                          {(formik) => (
                            <Form id={formId} autoComplete="off">
                              <Grid container spacing={3}>
                                <Grid item xs={12}>
                                  <BasicInformation
                                    mainForm={formik}
                                    mainFormField={formField}
                                    modeDisabled={modeFieldDisabled}
                                    dispatchMainError={dispatchError}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <ChildrenInformation
                                    mainForm={formik}
                                    mainFormField={formField}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <MDBox
                                    width="100%"
                                    display="flex"
                                    justifyContent="space-between"
                                  >
                                    <MDBox></MDBox>
                                    <MDButton
                                      disabled={formik.isSubmitting}
                                      type="submit"
                                      variant="gradient"
                                      color="dark"
                                    >
                                      {modeSubmit}
                                    </MDButton>
                                  </MDBox>
                                </Grid>
                              </Grid>
                            </Form>
                          )}
                        </Formik>
                      </MDBox>
                    </Grid>
                  </Grid>
                )}
              </MDBox>
              <Dialog open={open} onClose={closeStartDialog}>
                <DialogTitle textAlign="center">Alert!</DialogTitle>
                <DialogContent dividers>
                  <MDBox
                    borderRadius="lg"
                    sx={{ p: 1, mb: 2, border: "1px solid red" }}
                  >
                    <MDBox>
                      <MDTypography variant="body2" fontWeight="medium">
                        The participant ID already exists in the database.
                      </MDTypography>
                    </MDBox>
                    <MDBox>Please edit the participant ID to proceed</MDBox>
                  </MDBox>
                </DialogContent>
                <DialogActions>
                  <MDButton
                    size="small"
                    variant="gradient"
                    color="success"
                    onClick={closeStartDialog}
                  >
                    OKAY
                  </MDButton>
                </DialogActions>
              </Dialog>
            </MDBox>
            <DashboardFooter />
          </>
        )}
      </AllowedTo>
    </DashboardLayout>
  );
}

export default NewUser;
