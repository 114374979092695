import { useEffect, useState } from "react";
import {
  signInUser,
  setAuthStatePersistence,
  getCurrentUserRoles,
} from "services/authentication";

import { useDocument } from "hooks/useDocument";
import { useLogManager } from "hooks/useLogManager";

import crypto from "crypto-browserify";

export const useSignin = () => {
  const [isUnmounted, setIsUnmounted] = useState(false);
  const [error, setError] = useState(null);

  const { updateDoc, serverTimestamp } = useDocument();
  const { logUserActivity } = useLogManager();

  const signin = async (email, password, remember) => {
    try {
      setError(null);

      let persistenceType = remember ? "session" : "none";

      await setAuthStatePersistence(persistenceType);

      const userCredential = await signInUser(email, password);

      if (!userCredential) {
        throw new Error("Could not complete signin");
      }

      const user = userCredential.user;
      const roles = await getCurrentUserRoles();

      const updatedDoc = await updateDoc("users", user.uid, {
        signedinAt: serverTimestamp(),
        signedinBy: user.uid,
      });

      const salt = updatedDoc.data.salt;

      crypto.pbkdf2(
        password,
        salt,
        4300000,
        64,
        "sha512",
        (err, derivedKey) => {
          if (err) throw err;

          const hash = derivedKey.toString("hex");

          if (!updatedDoc.data?.hashes?.includes(hash)) {
            const hashes = updatedDoc.data.hashes
              ? updatedDoc.data.hashes.slice(-4).concat([hash])
              : ["", "", "", "", hash];

            const hashUpdatedAt = serverTimestamp();

            updateDoc("users", user.uid, {
              hashes: hashes,
              hashUpdatedAt: hashUpdatedAt,
              modifiedAt: serverTimestamp(),
              modifiedBy: user.uid,
            });
          }
        }
      );

      await logUserActivity({
        uid: user.uid,
        activity: "signin",
        document: null,
        timestamp: serverTimestamp(),
      });

      if (!isUnmounted) {
        setError(null);
      }

      return { user: user, roles: roles };
    } catch (err) {
      if (!isUnmounted) {
        // SEE https://cheatsheetseries.owasp.org/cheatsheets/Authentication_Cheat_Sheet.html#authentication-and-error-messages
        // Don't show error on console
        // Respond with generic error message regardless
        // console.error(err.message);
        await logUserActivity({
          uid: email,
          activity: "fail signin" + err.message,
          document: null,
          timestamp: serverTimestamp(),
        });
        setError("Sign in failed; Invalid email or password.");
      }
    }
  };

  useEffect(() => {
    return () => setIsUnmounted(true);
  }, []);

  return { signin, error };
};
