import PropTypes from "prop-types";

import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";
import MDAlert from "components/atoms/MDAlert";

function SuccessAlert({ message }) {
  return (
    <MDBox
      position="absolute"
      width="calc(100% - 2rem)"
      mx={2}
      my={2}
      zIndex={1000}
    >
      <MDAlert color="success" dismissible>
        <MDTypography variant="body2" color="white">
          {message}
        </MDTypography>
      </MDAlert>
    </MDBox>
  );
}

SuccessAlert.propTypes = {
  message: PropTypes.string.isRequired,
};

export default SuccessAlert;
