import PropTypes from "prop-types";

function ContentFragment({ children }) {
  return <>{children}</>;
}

// Typechecking props for the BackgroundLayout
ContentFragment.propTypes = {
  children: PropTypes.node,
};

export default ContentFragment;
