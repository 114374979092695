import { useState } from "react";

import Grid from "@mui/material/Grid";

import MDBox from "components/atoms/MDBox";
import MDButton from "components/atoms/MDButton";

import ErrorSnackbar from "components/molecules/Notifications/ErrorSnackbar";
import SuccessSnackbar from "components/molecules/Notifications/SuccessSnackbar";

import DashboardLayout from "components/templates/DashboardLayout";
import Topnav from "components/organisms/Topnav";
import DashboardFooter from "components/organisms/Footer/DashboardFooter";
import PermissionDenied from "components/organisms/PermissionDenied";
import InvalidOperation from "components/organisms/InvalidOperation";
import IncompleteOperation from "components/organisms/IncompleteOperation";

import SubmissionProgress from "components/organisms/SubmissionProgress";

import Accounts from "pages/settings/users/manage/components/Accounts";
import BasicInformation from "pages/settings/users/manage/components/BasicInformation";
import Sidenav from "pages/settings/users/manage/components/Sidenav";

import { Formik, Form } from "formik";
import form from "pages/settings/users/manage/schemas/form";

import { AllowedTo } from "react-abac";

import { useSettingsManager } from "pages/settings/users/hooks/useSettingsManager";

import { useAuthContext } from "context/AuthContext";
import { useLogManager } from "hooks/useLogManager";
import { useDocument } from "hooks/useDocument";

function ManageUsers() {
  const [isProgressing, setIsProgressing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [selectedAccount, setSelectedAccount] = useState(null);
  const [authUserDisabled, setAuthUserDisabled] = useState(null);
  const { user } = useAuthContext();

  const { logUserActivity } = useLogManager();
  const { serverTimestamp } = useDocument();
  const {
    modeTitle,
    modeSubmit,
    modeFieldDisabled,
    modeValidation,
    submitEdit,
    submitEnableUser,
    submitDisableUser,
    response,
    modePermission,
    dispatchDismiss,
    dispatchError,
  } = useSettingsManager();

  const dispatchDismissAfter = (millisecond) => {
    return new Promise(() => {
      setTimeout(() => {
        dispatchDismiss();
      }, millisecond);
    });
  };

  const { formId, formField } = form;

  const handleSubmit = async (values, actions) => {
    try {
      setIsProgressing(true);
      await submitEdit(selectedAccount.id, values);
    } catch (err) {
      dispatchError(err);
      actions.setSubmitting(false);
    } finally {
      actions.setSubmitting(false);
      setIsProgressing(false);
      await dispatchDismissAfter(3000);
    }
  };

  const handleDisable = async () => {
    try {
      setIsSaving(true);
      setIsProgressing(true);
      await submitDisableUser(selectedAccount.id);

      await logUserActivity({
        uid: user.uid,
        activity: "disable account",
        document: selectedAccount,
        timestamp: serverTimestamp(),
      });
    } catch (err) {
      dispatchError(err);
    } finally {
      setIsSaving(false);
      setIsProgressing(false);
      await dispatchDismissAfter(1000);
    }
  };

  const handleEnable = async () => {
    try {
      setIsSaving(true);
      setIsProgressing(true);
      await submitEnableUser(selectedAccount.id);
      await logUserActivity({
        uid: user.uid,
        activity: "enable account",
        document: selectedAccount,
        timestamp: serverTimestamp(),
      });
    } catch (err) {
      dispatchError(err);
    } finally {
      setIsSaving(false);
      setIsProgressing(true);
      await dispatchDismissAfter(1000);
    }
  };

  return (
    <DashboardLayout>
      <Topnav title={modeTitle} />
      <AllowedTo perform={modePermission} no={() => <PermissionDenied />}>
        {response.isPending || isProgressing ? (
          <SubmissionProgress />
        ) : (
          <>
            {response.error && (
              <>
                {response.error?.name === "OperationInvalidError" && (
                  <InvalidOperation />
                )}
                {response.error?.name === "OperationIncompleteError" && (
                  <IncompleteOperation />
                )}
                <ErrorSnackbar
                  title={modeTitle}
                  dateTime=""
                  message={response.error?.message}
                  autoDismiss
                />
              </>
            )}
            {response.success && (
              <SuccessSnackbar
                title={modeTitle}
                dateTime=""
                message={response.success}
                autoDismiss
              />
            )}
            <MDBox pt={3}>
              <MDBox>
                {response.error?.name !== "OperationInvalidError" && (
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={2}>
                      <Sidenav />
                    </Grid>

                    <Grid item xs={12} lg={10}>
                      <MDBox mb={3}>
                        <Formik
                          enableReinitialize
                          initialValues={response.data}
                          validationSchema={modeValidation}
                          onSubmit={handleSubmit}
                        >
                          {(formik) => (
                            <Form id={formId} autoComplete="off">
                              <Grid container spacing={3}>
                                <Grid item xs={12}>
                                  <Accounts
                                    mainForm={formik}
                                    mainFormField={formField}
                                    modeDisabled={modeFieldDisabled}
                                    dispatchMainError={dispatchError}
                                    selectedAccount={selectedAccount}
                                    setSelectedAccount={setSelectedAccount}
                                    authUserDisabled={authUserDisabled}
                                    setAuthUserDisabled={setAuthUserDisabled}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <BasicInformation
                                    mainForm={formik}
                                    mainFormField={formField}
                                    modeDisabled={modeFieldDisabled}
                                    dispatchMainError={dispatchError}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <MDBox
                                    width="100%"
                                    display="flex"
                                    justifyContent="space-between"
                                  >
                                    {authUserDisabled !== null ? (
                                      <>
                                        {authUserDisabled === true && (
                                          <MDButton
                                            disabled={
                                              formik.isSubmitting || isSaving
                                            }
                                            variant="gradient"
                                            color="success"
                                            onClick={() => handleEnable()}
                                          >
                                            Enable
                                          </MDButton>
                                        )}
                                        {authUserDisabled === false && (
                                          <MDButton
                                            disabled={
                                              formik.isSubmitting || isSaving
                                            }
                                            variant="gradient"
                                            color="error"
                                            onClick={() => handleDisable()}
                                          >
                                            Disable
                                          </MDButton>
                                        )}
                                      </>
                                    ) : (
                                      <MDBox></MDBox>
                                    )}
                                    <MDButton
                                      disabled={formik.isSubmitting || isSaving}
                                      type="submit"
                                      variant="gradient"
                                      color="dark"
                                    >
                                      {modeSubmit}
                                    </MDButton>
                                  </MDBox>
                                </Grid>
                              </Grid>
                            </Form>
                          )}
                        </Formik>
                      </MDBox>
                    </Grid>
                  </Grid>
                )}
              </MDBox>
            </MDBox>
            <DashboardFooter />
          </>
        )}
      </AllowedTo>
    </DashboardLayout>
  );
}

export default ManageUsers;
