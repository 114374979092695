import { useState } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";
import MDButton from "components/atoms/MDButton";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";

import FormTextFieldFast from "components/molecules/Formik/FormTextFieldFast";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { sendWhatsAppMsg } from "services/callable";

function BasicInformation({ mainForm, mainFormField, modeDisabled }) {
  const { userName, mobile, whatsappInvite } = mainFormField;
  const { setFieldValue } = mainForm;
  const [inviteCount, setInviteCount] = useState(0);
  const [open, setOpen] = useState(false);
  /*const showStartDialog = () => {
    setOpen(true);
  };*/
  const closeStartDialog = () => {
    setOpen(false);
  };

  return (
    <Card id="parent-basic-information" sx={{ overflow: "visible" }}>
      <MDBox m={3}>
        <MDBox mb={3}>
          <MDTypography variant="h5">Basic Information</MDTypography>
        </MDBox>

        <MDBox>
          <Grid
            container
            rowSpacing={{ xs: 1, sm: 2 }}
            columnSpacing={{ xs: 1, sm: 3 }}
          >
            <Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                {userName.label}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormTextFieldFast
                form={mainForm}
                field={userName}
                variant="standard"
                disabled={modeDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={4}></Grid>

            <Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                {mobile.label}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <PhoneInput
                country={"sg"}
                value={mainForm.values.mobile}
                onChange={(value) => {
                  if (value.length > 0) {
                    mainForm.values.mobile = value;
                    setFieldValue(mobile.name, value);
                  }
                }}
              />
              <Grid item xs={12} sm={6}>
                <FormTextFieldFast
                  form={mainForm}
                  field={mobile}
                  variant="standard"
                  disabled={true}
                  hideLabel={true}
                  hideField={true}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4}>
              <MDButton
                variant="gradient"
                color="success"
                onClick={async () => {
                  //if (mainForm.values.mobile.length === 10) {
                  const response = await sendWhatsAppMsg({
                    type: "template",
                    name: "cradle2_welcome",
                    mobile: mainForm.values.mobile,
                  });
                  console.log(JSON.stringify(response));
                  const newInviteCount = inviteCount + 1;
                  mainForm.values.whatsappInvite = newInviteCount;
                  setInviteCount(newInviteCount);
                  setFieldValue(whatsappInvite.name, newInviteCount.toString());
                  //} else {
                  //  showStartDialog();
                  //}
                }}
              >
                WhatsApp Invite
              </MDButton>
            </Grid>

            <Grid item xs={12} sm={2}></Grid>
            <Grid item xs={12} sm={6}>
              {inviteCount > 0 && (
                <MDTypography variant="body3" fontWeight="bold" color="success">
                  WhatsApp Invite Sent - #{inviteCount}
                </MDTypography>
              )}
              <Grid item xs={12} sm={6}>
                <FormTextFieldFast
                  form={mainForm}
                  field={whatsappInvite}
                  variant="standard"
                  disabled={true}
                  hideLabel={true}
                  hideField={true}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4}></Grid>
          </Grid>
        </MDBox>
        <Dialog open={open} onClose={closeStartDialog}>
          <DialogTitle textAlign="center">ALERT!</DialogTitle>
          <DialogContent dividers>
            <MDBox
              borderRadius="lg"
              sx={{ p: 1, mb: 2, border: "1px solid red" }}
            >
              <MDBox>
                <MDTypography variant="body2" fontWeight="medium">
                  Invalid Mobile Number
                </MDTypography>
              </MDBox>
              <MDBox>Please enter a valid mobile number to proceed.</MDBox>
            </MDBox>
          </DialogContent>
          <DialogActions>
            <MDButton
              size="small"
              variant="gradient"
              color="success"
              onClick={closeStartDialog}
            >
              OKAY
            </MDButton>
          </DialogActions>
        </Dialog>
      </MDBox>
    </Card>
  );
}

export default BasicInformation;
